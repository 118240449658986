// // src/redux/reducers/streamingBarSlice.js
// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   isPlaying: false,
//   isPlayingArchive: false, // New state property
//   currentRunningProgram: {
//     id: '',
//     showPic: '',
//     title: '',
//     rjName: '',
//   },
//   liveStreamUrl: 'https://radiosurabhi.streamguys1.com/live1-web',
//   archiveUrl: '', // Placeholder for future archive URL functionality
// };

// const streamingBarSlice = createSlice({
//   name: 'streamingBar',
//   initialState,
//   reducers: {
//     togglePlayPause: (state) => {
//       state.isPlaying = !state.isPlaying;
//     },
//     setProgram: (state, action) => {
//       state.currentRunningProgram = action.payload;
//     },
//     setArchiveUrl: (state, action) => {
//       state.archiveUrl = action.payload;
//       state.isPlayingArchive = action.payload !== '';
//     },
//   },
// });

// export const { togglePlayPause, setProgram, setArchiveUrl } = streamingBarSlice.actions;

// export default streamingBarSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPlaying: false,
  isPlayingArchive: false,
  currentRunningProgram: {
    id: '',
    showPic: '',
    title: '',
    rjName: '',
  },
  liveStreamUrl: 'https://radiosurabhi.streamguys1.com/live1-web',
  archiveUrl: '',
  streamingURLs: [],
  selectedCity: null,
};

const streamingBarSlice = createSlice({
  name: 'streamingBar',
  initialState,
  reducers: {
    togglePlayPause: (state) => {
      state.isPlaying = !state.isPlaying;
    },
    setProgram: (state, action) => {
      state.currentRunningProgram = action.payload;
    },
    setArchiveUrl: (state, action) => {
      state.archiveUrl = action.payload;
      state.isPlayingArchive = action.payload !== '';
    },
    setStreamingURLs: (state, action) => {
      state.streamingURLs = action.payload;
    },
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
      state.liveStreamUrl = action.payload.streamingURL;
    },
    goToLiveStream: (state) => {
      state.archiveUrl = '';
      state.isPlayingArchive = false;
      const defaultCity = state.streamingURLs.find(city => city.streamStatus === 'default');
      state.selectedCity = defaultCity;
      state.liveStreamUrl = defaultCity ? defaultCity.streamingURL : state.liveStreamUrl;
    },
  },
});

export const { togglePlayPause, setProgram, setArchiveUrl, setStreamingURLs, setSelectedCity, goToLiveStream } = streamingBarSlice.actions;

export default streamingBarSlice.reducer;
