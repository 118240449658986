// src/redux/actions/rjPostersActions.js

import { fetchRjPostersStart, fetchRjPostersSuccess, fetchRjPostersFailure } from '../reducers/rjPostersSlice';
import apiService from '../api-service/apiService';

export const fetchRjPostersList = (forGuest) => async (dispatch, getState) => {
  dispatch(fetchRjPostersStart());

  try {
    const { userData } = getState().user;
    const rjPosters = await apiService.fetchRjPosters(forGuest, userData?.identityToken, userData?.id);
    dispatch(fetchRjPostersSuccess(rjPosters));
  } catch (error) {
    dispatch(fetchRjPostersFailure(error.message));
  }
};
