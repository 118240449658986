import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { togglePlayPause, setArchiveUrl } from '../redux/reducers/streamingBarSlice';

const AudioPlayerContext = createContext();

export const useAudioPlayer = () => useContext(AudioPlayerContext);

export const AudioPlayerProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { isPlaying, liveStreamUrl, archiveUrl } = useSelector((state) => state.streamingBar);
  const audioRef = useRef(new Audio());
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    const updateCurrentTime = () => setCurrentTime(audio.currentTime);
    const updateDuration = () => setDuration(audio.duration);

    audio.addEventListener('timeupdate', updateCurrentTime);
    audio.addEventListener('loadedmetadata', updateDuration);

    if (archiveUrl) {
      audio.src = archiveUrl;
    } else {
      audio.src = liveStreamUrl;
    }

    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && isPlaying) {
        audio.play();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      audio.removeEventListener('timeupdate', updateCurrentTime);
      audio.removeEventListener('loadedmetadata', updateDuration);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      audio.pause();
    };
  }, [isPlaying, liveStreamUrl, archiveUrl]);

  const handleTogglePlayPause = () => {
    dispatch(togglePlayPause());
  };

  const handleTimeChange = (value) => {
    audioRef.current.currentTime = value;
    setCurrentTime(value);
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
  };

  return (
    <AudioPlayerContext.Provider value={{ currentTime, duration, isPlaying, handleTogglePlayPause, handleTimeChange, formatTime, audioRef }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};
