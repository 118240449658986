// import {
//   signIn,
//   signUp,
//   signInWithRedirect,
//   fetchAuthSession,
//   confirmSignUp,
//   updateUserAttributes,
//   signOut,
// } from 'aws-amplify/auth';
// import { loginStart, loginSuccess, loginFailure } from '../reducers/userSlice';

// const apiUrl = process.env.REACT_APP_API_BASE_URL;
// export const signUpUser = ({ email, password, userData }) => async (dispatch) => {
//   console.log("Signing up")
//   dispatch(loginStart());
//   try {
//     const { firstName, lastName, phoneNumber, uuid } = userData;
//     console.log(userData);
//     const result = await signUp({
//       username: email,
//       password: password,
//       options: {
//         userAttributes:{
//           email: email,
//           phone_number: phoneNumber,
//           'custom:firstName': firstName,
//           'custom:lastName': lastName,
//           'custom:uuid': uuid,
//         },
//       },
//     });
//     console.log(result);
//     return { success: true, result };
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//     return { success: false, error };
//   }
// };

// export const confirmUserSignUp = ({ email, code }) => async (dispatch) => {
//   dispatch(loginStart());
//   try {
//     const result = await confirmSignUp({ username: email, confirmationCode: code });
//     return { success: true, result };
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//     return { success: false, error };
//   }
// };

// export const autoLoginUser = ({ email, password }) => async (dispatch) => {
//   dispatch(loginStart());
//   try {
//     const user = await signIn({ username: email, password });
//     const session = await fetchAuthSession();
//     const tokenData = session.tokens.idToken.payload;
//     const userData = { ...user.attributes, accessToken: session.tokens.accessToken, idToken: session.tokens.idToken };
//     dispatch(loginSuccess(userData));
//     return userData;
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//     return null;
//   }
// };

// export const signInWithGoogle = () => async (dispatch) => {
//   dispatch(loginStart());
//   try {
//     await signInWithRedirect({ provider: 'Google' });
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//   }
// };

// export const fetchGoogleSignInSession = () => async (dispatch) => {
//   dispatch(loginStart());
//   try {
//     const session = await fetchAuthSession();
//     const tokenData = session.tokens.idToken.payload;
//     const email = tokenData.email;
//     const userData = { ...tokenData, accessToken: session.tokens.accessToken, idToken: session.tokens.idToken };
//     dispatch(loginSuccess(userData));
//     return userData;
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//     return null;
//   }
// };

// export const checkUserExists = (email) => async () => {
//   const url = `${apiUrl}user/user-exists`;
//   try {
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ email }),
//     });

//     if (response.ok) {
//       const jsonData = await response.json();
//       return jsonData.userExists;
//     } else {
//       console.log(`Failed to check if user exists: ${response.status}`);
//       return false;
//     }
//   } catch (e) {
//     console.log(`Error checking if user exists: ${e}`);
//     return false;
//   }
// };

// export const updateUserInDatabase = async (userData) => {
//   const url = `${apiUrl}signupSSO`;
//   const body = JSON.stringify(userData);
//   const response = await fetch(url, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body,
//   });

//   if (!response.ok) {
//     throw new Error(`Signup failed: ${response.statusText}`);
//   }

//   return response.json();
// };

// import {
//   signIn,
//   signUp,
//   signInWithRedirect,
//   fetchAuthSession,
//   confirmSignUp,
//   updateUserAttributes,
//   signOut,
// } from 'aws-amplify/auth';
// import { loginStart, loginSuccess, loginFailure } from '../reducers/userSlice';

// const apiUrl = process.env.REACT_APP_API_BASE_URL;

// export const signUpUser = ({ email, password, userData }) => async (dispatch) => {
//   dispatch(loginStart());
//   try {
//     const { firstName, lastName, phoneNumber, uuid } = userData;
//     const result = await signUp({
//       username: email,
//       password: password,
//       attributes: {
//         email: email,
//         phone_number: phoneNumber,
//         'custom:firstName': firstName,
//         'custom:lastName': lastName,
//         'custom:uuid': uuid,
//       },
//     });
//     return { success: true, result };
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//     return { success: false, error };
//   }
// };

// export const confirmUserSignUp = ({ email, code }) => async (dispatch) => {
//   dispatch(loginStart());
//   try {
//     const result = await confirmSignUp(email, code);
//     return { success: true, result };
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//     return { success: false, error };
//   }
// };

// export const autoLoginUser = ({ email, password }) => async (dispatch) => {
//   dispatch(loginStart());
//   try {
//     const user = await signIn(email, password);
//     const session = await fetchAuthSession();
//     const tokenData = session.idToken.payload;
//     const userData = { ...user.attributes, accessToken: session.accessToken.jwtToken, idToken: session.idToken.jwtToken };
//     dispatch(loginSuccess(userData));
//     return userData;
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//     return null;
//   }
// };

// export const signInWithGoogle = () => async (dispatch) => {
//   dispatch(loginStart());
//   try {
//     await signInWithRedirect({ provider: 'Google' });
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//   }
// };

// export const fetchGoogleSignInSession = () => async (dispatch) => {
//   dispatch(loginStart());
//   try {
//     const session = await fetchAuthSession();
//     const tokenData = session.idToken.payload;
//     const email = tokenData.email;
//     const userData = { ...tokenData, accessToken: session.accessToken.jwtToken, idToken: session.idToken.jwtToken };
//     dispatch(loginSuccess(userData));
//     return userData;
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//     return null;
//   }
// };

// export const checkUserExists = (email) => async () => {
//   const url = `https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists`;
//   try {
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ email }),
//     });

//     if (response.ok || response.status == 404) {
//       const jsonData = await response.json();
//       console.log("User data"+jsonData.userExists);
//       return jsonData.userExists;
//     } else {
//       console.log(`Failed to check if user exists: ${response.status}`);
//       return false;
//     }
//   } catch (e) {
//     console.log(`Error checking if user exists: ${e}`);
//     return false;
//   }
// };

// export const updateUserInDatabase = async (userData) => {
//   const url = `${apiUrl}signupSSO`;
//   const body = JSON.stringify(userData);
//   const response = await fetch(url, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body,
//   });

//   if (!response.ok) {
//     throw new Error(`Signup failed: ${response.statusText}`);
//   }

//   return response.json();
// };


import { signIn, signUp, signInWithRedirect, fetchAuthSession, confirmSignUp, signOut } from 'aws-amplify/auth';
import { loginStart, loginSuccess, loginFailure, logout } from '../reducers/userSlice';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
export const signUpUser = ({ email, password, userData }) => async (dispatch) => {
    console.log("Signing up")
    dispatch(loginStart());
    try {
      const { firstName, lastName, phoneNumber, uuid } = userData;
      console.log(userData);
      const result = await signUp({
        username: email,
        password: password,
        options: {
          userAttributes:{
            email: email,
            phone_number: phoneNumber,
            'custom:firstName': firstName,
            'custom:lastName': lastName,
            'custom:uuid': uuid,
          },
        },
      });
      console.log(result);
      return { success: true, result };
    } catch (error) {
      dispatch(loginFailure(error.message));
      return { success: false, error };
    }
  };

export const confirmUserSignUp = ({ email, code }) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const result = await confirmSignUp({ username: email, confirmationCode: code });
    return { success: true, result };
  } catch (error) {
    dispatch(loginFailure(error.message));
    return { success: false, error };
  }
};

export const autoLoginUser = ({ email, password }) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const user = await signIn({ username: email, password });
    const session = await fetchAuthSession();
    const tokenData = session.tokens.idToken.payload;
    const userData = { ...user.attributes, accessToken: session.tokens.accessToken, idToken: session.tokens.idToken };
    dispatch(loginSuccess(userData));
    return userData;
  } catch (error) {
    dispatch(loginFailure(error.message));
    return null;
  }
};

export const signInWithGoogle = () => async (dispatch) => {
  dispatch(loginStart());
  try {
    await signInWithRedirect({ provider: 'Google' });
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const fetchGoogleSignInSession = () => async (dispatch) => {
  dispatch(loginStart());
  try {
    const session = await fetchAuthSession();
    const tokenData = session.idToken.payload;
    const email = tokenData.email;
    const userData = { ...tokenData, accessToken: session.accessToken.jwtToken, idToken: session.idToken.jwtToken };
    localStorage.setItem('user', JSON.stringify(userData)); // Persist user state
    dispatch(loginSuccess(userData));
    return userData;
  } catch (error) {
    dispatch(loginFailure(error.message));
    return null;
  }
};

export const checkUserExists = (email) => async () => {
  const url = `https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    if (response.ok || response.status === 404) {
      const jsonData = await response.json();
      return jsonData.userExists;
    } else {
      console.log(`Failed to check if user exists: ${response.status}`);
      return false;
    }
  } catch (e) {
    console.log(`Error checking if user exists: ${e}`);
    return false;
  }
};

export const updateUserInDatabase = async (userData) => {
  const url = `${apiUrl}signupSSO`;
  const body = JSON.stringify(userData);
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  });

  if (!response.ok) {
    throw new Error(`Signup failed: ${response.statusText}`);
  }

  return response.json();
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('user'); // Remove user state from localStorage
  dispatch(logout());
};
