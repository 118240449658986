// // src/App.js
// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import Login from './pages/Login';
// import Home from './pages/Home';
// import SVerse from './pages/Sverse';
// import Schedule from './pages/Schedule';
// import AboutUs from './pages/AboutUs';
// import AdManager from './components/AdManager';
// import NavigationBar from './components/NavigationBar';
// import StreamingBar from './components/StreamingBar';
// import Profile from './pages/Profile';
// import Privacy from './pages/Privacy';
// import DetailedStreamingBar from './pages/DetailedStreamingBar';
// import Signup from './pages/Signup';

// const App = () => {
//   const userData = useSelector((state) => state.user.userData);

//   return (
//     <Router>
//       <div className="App">
//         <AdManager />
//         <Routes>
//           <Route path="/" element={userData ? <Home /> : <Navigate to="/login" />} />
//           <Route path="/login" element={!userData ? <Login /> : <Navigate to="/" />} />
//           <Route path="/signup" element={!userData ? <Signup /> : <Navigate to="/" />} />
//           <Route path="/sverse" element={userData ? <SVerse /> : <Navigate to="/login" />} />
//           <Route path="/schedule" element={userData ? <Schedule /> : <Navigate to="/login" />} />
//           <Route path="/about" element={userData ? <AboutUs /> : <Navigate to="/login" />} />
//           <Route path="/profile" element={userData ? <Profile /> : <Navigate to="/login" />} />
//           <Route path="/privacy" element={userData ? <Privacy /> : <Navigate to="/login" />} />
//           <Route path='/streaming/:programId' element={userData ? <DetailedStreamingBar /> : <Navigate to="/login" />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;


// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import Login from './pages/Login';
// import Home from './pages/Home';
// import SVerse from './pages/Sverse';
// import Schedule from './pages/Schedule';
// import AboutUs from './pages/AboutUs';
// import AdManager from './components/AdManager';
// import NavigationBar from './components/NavigationBar';
// import StreamingBar from './components/StreamingBar';
// import Profile from './pages/Profile';
// import Privacy from './pages/Privacy';
// import DetailedStreamingBar from './pages/DetailedStreamingBar';
// import Signup from './pages/Signup';

// const App = () => {
//   const userData = useSelector((state) => state.user.userData);

//   return (
//     <Router>
//       <div className="App">
//         <AdManager />
//         <Routes>
//           <Route path="/" element={userData ? <Home /> : <Navigate to="/login" />} />
//           <Route path="/login" element={!userData ? <Login /> : <Navigate to="/" />} />
//           <Route path="/signup" element={!userData ? <Signup /> : <Navigate to="/" />} />
//           <Route path="/sverse" element={userData ? <SVerse /> : <Navigate to="/login" />} />
//           <Route path="/schedule" element={userData ? <Schedule /> : <Navigate to="/login" />} />
//           <Route path="/about" element={userData ? <AboutUs /> : <Navigate to="/login" />} />
//           <Route path="/profile" element={userData ? <Profile /> : <Navigate to="/login" />} />
//           <Route path="/privacy" element={userData ? <Privacy /> : <Navigate to="/login" />} />
//           <Route path='/streaming/:programId' element={userData ? <DetailedStreamingBar /> : <Navigate to="/login" />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { loginSuccess, logout } from './redux/reducers/userSlice';
// import { fetchAuthSession } from 'aws-amplify/auth';
// import Login from './pages/Login';
// import Home from './pages/Home';
// import SVerse from './pages/Sverse';
// import Schedule from './pages/Schedule';
// import AboutUs from './pages/AboutUs';
// import AdManager from './components/AdManager';
// import Profile from './pages/Profile';
// import Privacy from './pages/Privacy';
// import DetailedStreamingBar from './pages/DetailedStreamingBar';
// import Signup from './pages/Signup';
// import Landing from './pages/Landing';
// import ContactUs from './pages/ContactUs';
// import rsLoader from './assets/rs-loader.gif'; // Import the loader

// const App = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const validateSession = async () => {
//       const storedUser = localStorage.getItem('user');
//       if (storedUser) {
//         const parsedUser = JSON.parse(storedUser);
//         dispatch(loginSuccess(parsedUser));

//         try {
//           const session = await fetchAuthSession();
//           if (session && session.tokens) {
//             // Session is valid
//           } else {
//             throw new Error('Session invalid');
//           }
//         } catch (error) {
//           dispatch(logout());
//         }
//       }
//       setIsLoading(false);
//     };

//     validateSession();
//   }, [dispatch]);

//   if (isLoading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <img src={rsLoader} alt="Loading..." className="w-28 h-auto" />
//       </div>
//     ); // Display the loader in the center of the screen
//   }

//   return (
//     <Router>
//       <div className="App">
//         <AdManager />
//         <Routes>
//           <Route path="/" element={<Landing />} />
//           <Route path="/home" element={userData ? <Home /> : <Navigate to="/login" />} />
//           <Route path="/login" element={!userData  ? <Login /> : <Navigate to="/home" />} />
//           <Route path="/signup" element={!userData ? <Signup /> : <Navigate to="/home" />} />
//           <Route path="/sverse" element={userData ? <SVerse /> : <Navigate to="/login" />} />
//           <Route path="/schedule" element={userData ? <Schedule /> : <Navigate to="/login" />} />
//           <Route path="/about" element={userData ? <AboutUs /> : <Navigate to="/login" />} />
//           <Route path="/profile" element={userData ? <Profile /> : <Navigate to="/login" />} />
//           <Route path="/privacy" element={<Privacy />} />
//           <Route path="/contact" element={userData ? <ContactUs /> : <Navigate to="/login" />} />
//           <Route path="/streaming/:programId" element={userData ? <DetailedStreamingBar /> : <Navigate to="/login" />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// };

// export default App;


// // App.js
// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { loginSuccess, logout } from './redux/reducers/userSlice';
// import { fetchAuthSession } from 'aws-amplify/auth';
// import Login from './pages/Login';
// import Home from './pages/Home';
// import SVerse from './pages/Sverse';
// import Schedule from './pages/Schedule';
// import AboutUs from './pages/AboutUs';
// import AdManager from './components/AdManager';
// import Profile from './pages/Profile';
// import Privacy from './pages/Privacy';
// import DetailedStreamingBar from './pages/DetailedStreamingBar';
// import Signup from './pages/Signup';
// import Landing from './pages/Landing';
// import ContactUs from './pages/ContactUs';
// import rsLoader from './assets/rs-loader.gif'; // Import the loader
// import { AudioPlayerProvider } from './context/AudioPlayerContext'; // Import the AudioPlayerProvider

// const App = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const validateSession = async () => {
//       const storedUser = localStorage.getItem('user');
//       if (storedUser) {
//         const parsedUser = JSON.parse(storedUser);
//         dispatch(loginSuccess(parsedUser));

//         try {
//           const session = await fetchAuthSession();
//           if (session && session.tokens) {
//             // Session is valid
//           } else {
//             throw new Error('Session invalid');
//           }
//         } catch (error) {
//           dispatch(logout());
//         }
//       }
//       setIsLoading(false);
//     };

//     validateSession();
//   }, [dispatch]);

//   if (isLoading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <img src={rsLoader} alt="Loading..." className="w-28 h-auto" />
//       </div>
//     ); // Display the loader in the center of the screen
//   }

//   return (
//     <Router>
//       <AudioPlayerProvider>
//         <div className="App">
//           <AdManager />
//           <Routes>
//             <Route path="/" element={<Landing />} />
//             <Route path="/home" element={userData ? <Home /> : <Navigate to="/login" />} />
//             <Route path="/login" element={!userData  ? <Login /> : <Navigate to="/home" />} />
//             <Route path="/signup" element={!userData ? <Signup /> : <Navigate to="/home" />} />
//             <Route path="/sverse" element={userData ? <SVerse /> : <Navigate to="/login" />} />
//             <Route path="/schedule" element={userData ? <Schedule /> : <Navigate to="/login" />} />
//             <Route path="/about" element={userData ? <AboutUs /> : <Navigate to="/login" />} />
//             <Route path="/profile" element={userData ? <Profile /> : <Navigate to="/login" />} />
//             <Route path="/privacy" element={<Privacy />} />
//             <Route path="/contact" element={userData ? <ContactUs /> : <Navigate to="/login" />} />
//             <Route path="/streaming/:programId" element={userData ? <DetailedStreamingBar /> : <Navigate to="/login" />} />
//           </Routes>
//         </div>
//       </AudioPlayerProvider>
//     </Router>
//   );
// };

// export default App;

// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { loginSuccess, logout } from './redux/reducers/userSlice';
// import { fetchAuthSession } from 'aws-amplify/auth';
// import Login from './pages/Login';
// import Home from './pages/Home';
// import SVerse from './pages/Sverse';
// import Schedule from './pages/Schedule';
// import AboutUs from './pages/AboutUs';
// import AdManager from './components/AdManager';
// import Profile from './pages/Profile';
// import Privacy from './pages/Privacy';
// import DetailedStreamingBar from './pages/DetailedStreamingBar';
// import Signup from './pages/Signup';
// import Landing from './pages/Landing';
// import ContactUs from './pages/ContactUs';
// import rsLoader from './assets/rs-loader.gif'; // Import the loader
// import { AudioPlayerProvider } from './context/AudioPlayerContext'; // Import the AudioPlayerProvider

// const App = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const validateSession = async () => {
//       const storedUser = localStorage.getItem('user');
//       if (storedUser) {
//         const parsedUser = JSON.parse(storedUser);
//         dispatch(loginSuccess(parsedUser));

//         try {
//           const session = await fetchAuthSession();
//           if (session && session.tokens) {
//             // Session is valid
//           } else {
//             throw new Error('Session invalid');
//           }
//         } catch (error) {
//           dispatch(logout());
//         }
//       }
//       setIsLoading(false);
//     };

//     validateSession();
//   }, [dispatch]);

//   if (isLoading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <img src={rsLoader} alt="Loading..." className="w-28 h-auto" />
//       </div>
//     ); // Display the loader in the center of the screen
//   }

//   return (
//     <Router>
//       <AudioPlayerProvider>
//         <div className="App">
//           <AdManager />
//           <Routes>
//             <Route path="/" element={<Landing />} />
//             <Route path="/home" element={userData ? <Home /> : <Navigate to="/login" />} />
//             <Route path="/login" element={!userData  ? <Login /> : <Navigate to="/home" />} />
//             <Route path="/signup" element={!userData ? <Signup /> : <Navigate to="/home" />} />
//             <Route path="/sverse" element={userData ? <SVerse /> : <Navigate to="/login" />} />
//             <Route path="/schedule" element={userData ? <Schedule /> : <Navigate to="/login" />} />
//             <Route path="/about" element={userData ? <AboutUs /> : <Navigate to="/login" />} />
//             <Route path="/profile" element={userData ? <Profile /> : <Navigate to="/login" />} />
//             <Route path="/privacy" element={<Privacy />} />
//             <Route path="/contact" element={userData ? <ContactUs /> : <Navigate to="/login" />} />
//             <Route path="/streaming/:programId" element={userData ? <DetailedStreamingBar /> : <Navigate to="/login" />} />
//           </Routes>
//         </div>
//       </AudioPlayerProvider>
//     </Router>
//   );
// };

// export default App;


import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, logout } from './redux/reducers/userSlice';
import { fetchAuthSession } from 'aws-amplify/auth';
import Login from './pages/Login';
import Home from './pages/Home';
import SVerse from './pages/Sverse';
import Schedule from './pages/Schedule';
import AboutUs from './pages/AboutUs';
import AdManager from './components/AdManager';
import Profile from './pages/Profile';
import Privacy from './pages/Privacy';
import DetailedStreamingBar from './pages/DetailedStreamingBar';
import Signup from './pages/Signup';
import Landing from './pages/Landing';
import ContactUs from './pages/ContactUs';
import rsLoader from './assets/rs-loader.gif'; // Import the loader
import { AudioPlayerProvider } from './context/AudioPlayerContext'; // Import the AudioPlayerProvider
import apiService from './redux/api-service/apiService';

const App = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateSession = async () => {
      const storedUser = localStorage.getItem('user');
      console.log(storedUser)
      if (storedUser) {
        console.log("Inside if block");
        const parsedUser = JSON.parse(storedUser);
        dispatch(loginSuccess(parsedUser));

        try {
          const session = await fetchAuthSession();
          if (session && session.tokens) {
            const userExists = await apiService.checkUserExists(parsedUser.email);
            console.log(userExists);
            if (!userExists) {
              dispatch(logout());
            }
          } else {
            throw new Error('Session invalid');
          }
        } catch (error) {
          dispatch(logout());
        }
      }
      setIsLoading(false);
    };

    validateSession();
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={rsLoader} alt="Loading..." className="w-28 h-auto" />
      </div>
    ); // Display the loader in the center of the screen
  }

  return (
    <Router>
      <AudioPlayerProvider>
        <div className="App">
          <AdManagerWrapper />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/home" element={userData ? <Home /> : <Navigate to="/login" />} />
            <Route path="/login" element={!userData ? <Login /> : <Navigate to="/home" />} />
            <Route path="/signup" element={!userData ? <Signup /> : <Navigate to="/home" />} />
            <Route path="/sverse" element={userData ? <SVerse /> : <Navigate to="/login" />} />
            <Route path="/schedule" element={userData ? <Schedule /> : <Navigate to="/login" />} />
            <Route path="/about" element={userData ? <AboutUs /> : <Navigate to="/login" />} />
            <Route path="/profile" element={userData ? <Profile /> : <Navigate to="/login" />} />
            <Route path="/terms-privacy" element={<Privacy />} />
            <Route path="/contact" element={userData ? <ContactUs /> : <Navigate to="/login" />} />
            <Route path="/streaming/:programId" element={userData ? <DetailedStreamingBar /> : <Navigate to="/login" />} />
          </Routes>
        </div>
      </AudioPlayerProvider>
    </Router>
  );
};

const AdManagerWrapper = () => {
  const location = useLocation();
  const noAdPages = ['/terms-privacy'];

  return !noAdPages.includes(location.pathname) ? <AdManager /> : null;
};

export default App;
