// import React from 'react';
// import { FaTimes } from 'react-icons/fa';

// const PopUpAd = ({ ad, onClose }) => {
//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
//       <div className="bg-white rounded-lg p-4 relative max-w-lg w-full md:w-96 dark:bg-gray-900">
//         <button onClick={onClose} className="absolute text-gray-700 dark:text-gray-200 top-2 left-2 p-2">
//           <FaTimes />
//         </button>
//         <a href={ad.redirectionLink} target="_blank" rel="noopener noreferrer">
//           <img src={ad.thumbnail} alt={ad.title} className="w-full h-72 md:h-[36rem] object-contain rounded-lg" />
//         </a>
//       </div>
//     </div>
//   );
// };

// export default PopUpAd;


import React from 'react';
import { FaTimes } from 'react-icons/fa';

const PopUpAd = ({ ad, onClose }) => {
  const handleBackgroundClick = (e) => {
    if (e.target.id === 'popup-ad-background') {
      onClose();
    }
  };

  return (
    <div 
      id="popup-ad-background" 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white rounded-lg p-4 relative max-w-lg w-full md:w-auto dark:bg-gray-900">
        <button onClick={onClose} className="absolute text-gray-700 dark:text-gray-200 top-2 right-2 p-2">
          <FaTimes />
        </button>
        <a href={ad.redirectionLink} target="_blank" rel="noopener noreferrer">
          <img src={ad.thumbnail} alt={ad.title} className="w-96 h-auto object-contain rounded-lg" />
        </a>
      </div>
    </div>
  );
};

export default PopUpAd;
