// // // src/components/StreamingBar.js
// // import React, { useEffect, useRef, useState } from 'react';
// // import { useSelector, useDispatch } from 'react-redux';
// // import { FaPlay, FaPause, FaVolumeUp, FaBroadcastTower } from 'react-icons/fa';
// // import { togglePlayPause, setArchiveUrl } from '../redux/reducers/streamingBarSlice';
// // import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';

// // const StreamingBar = ({ isCollapsed }) => {
// //   const dispatch = useDispatch();
// //   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl } = useSelector((state) => state.streamingBar);
// //   const audioRef = useRef(new Audio());
// //   const [currentTime, setCurrentTime] = useState(0);
// //   const [duration, setDuration] = useState(0);
// //   const [isHovered, setIsHovered] = useState(false);

// //   useEffect(() => {
// //     const audio = audioRef.current;

// //     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
// //     const updateDuration = () => setDuration(audio.duration);

// //     audio.addEventListener('timeupdate', updateCurrentTime);
// //     audio.addEventListener('loadedmetadata', updateDuration);

// //     if (archiveUrl) {
// //       audio.src = archiveUrl;
// //     } else {
// //       audio.src = liveStreamUrl;
// //     }

// //     if (isPlaying) {
// //       audio.play();
// //     } else {
// //       audio.pause();
// //     }

// //     return () => {
// //       audio.removeEventListener('timeupdate', updateCurrentTime);
// //       audio.removeEventListener('loadedmetadata', updateDuration);
// //       audio.pause();
// //     };
// //   }, [isPlaying, liveStreamUrl, archiveUrl]);

// //   const handleTogglePlayPause = () => {
// //     dispatch(togglePlayPause());
// //   };

// //   const handleGoLive = () => {
// //     dispatch(setArchiveUrl(''));
// //     dispatch(updateCurrentRunningProgram({
// //       title: 'Live Program Title', // Replace with the actual live program title
// //       rjName: 'RJ Name', // Replace with the actual RJ name
// //       showPic: 'https://path.to.live.show.pic', // Replace with the actual live show picture
// //     }));
// //     dispatch(togglePlayPause()); // Ensure the audio starts playing
// //   };

// //   const formatTime = (seconds) => {
// //     const h = Math.floor(seconds / 3600);
// //     const m = Math.floor((seconds % 3600) / 60);
// //     const s = Math.floor(seconds % 60);
// //     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
// //   };

// //   const leftPosition = isCollapsed ? 'left-[10rem]' : 'left-[20rem]';

// //   return (
// //     <div
// //       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center p-4 h-14 fixed bottom-4 ${leftPosition} right-14 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
// //       onMouseEnter={() => setIsHovered(true)}
// //       onMouseLeave={() => setIsHovered(false)}
// //     >
// //       {archiveUrl && isHovered && (
// //         <div className="absolute top-0 left-0 w-full flex items-center">
// //           <span className="text-xs text-gray-600 dark:text-gray-400 ml-4">{formatTime(currentTime)}</span>
// //           <input
// //             type="range"
// //             className="flex-1 mx-2"
// //             value={currentTime}
// //             max={duration}
// //             onChange={(e) => {
// //               audioRef.current.currentTime = e.target.value;
// //               setCurrentTime(e.target.value);
// //             }}
// //           />
// //           <span className="text-xs text-gray-600 dark:text-gray-400 mr-4">{formatTime(duration)}</span>
// //         </div>
// //       )}
// //       <div className="flex items-center flex-shrink-0">
// //         <img src={currentRunningProgram.showPic} alt="Show" className="w-10 h-10 object-cover rounded-lg mr-4" />
// //         <div className='p-4'>
// //           <h3 className="text-sm font-semibold">{currentRunningProgram.title}</h3>
// //           <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
// //         </div>
// //       </div>
// //       <div className="flex flex-1 justify-center items-center space-x-4">
// //         <button onClick={handleTogglePlayPause} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 text-2xl">
// //           {isPlaying ? <FaPause /> : <FaPlay />}
// //         </button>
// //       </div>
// //       <div className="flex items-center flex-shrink-0 ml-4 space-x-2">
// //         {archiveUrl && (
// //           <button onClick={handleGoLive} className="text-blue-500 hover:text-blue-700 flex items-center">
// //             <FaBroadcastTower className="mr-2" /> Go Live
// //           </button>
// //         )}
// //         <button className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
// //           <FaVolumeUp />
// //         </button>
// //       </div>
// //     </div>
// //   );
// // };

// // import React, { useEffect, useRef, useState } from 'react';
// // import { useSelector, useDispatch } from 'react-redux';
// // import { FaPlay, FaPause, FaVolumeUp, FaBroadcastTower, FaComments } from 'react-icons/fa';
// // import { togglePlayPause, setArchiveUrl } from '../redux/reducers/streamingBarSlice';
// // import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
// // import { useNavigate } from 'react-router-dom';
// // import onAirImage from '../assets/on air.png'; // Import the "On Air" image

// // const StreamingBar = ({ isCollapsed }) => {
// //   const dispatch = useDispatch();
// //   const navigate = useNavigate();
// //   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl } = useSelector((state) => state.streamingBar);
// //   const { programs } = useSelector((state) => state.programs);
// //   const { userData } = useSelector((state) => state.user);
// //   const audioRef = useRef(new Audio());
// //   const [currentTime, setCurrentTime] = useState(0);
// //   const [duration, setDuration] = useState(0);
// //   const [isHovered, setIsHovered] = useState(false);
// //   const [volume, setVolume] = useState(1); // State to manage volume
// //   const [isVolumeOpen, setIsVolumeOpen] = useState(false); // State to toggle volume slider visibility

// //   const convertTimeToMinutes = (timeString) => {
// //     const [time, modifier] = timeString.split(' ');
// //     let [hours, minutes] = time.split(':');
// //     hours = parseInt(hours, 10);
// //     minutes = parseInt(minutes, 10);

// //     if (modifier === 'PM' && hours !== 12) {
// //       hours += 12;
// //     } else if (modifier === 'AM' && hours === 12) {
// //       hours = 0;
// //     }

// //     return hours * 60 + minutes;
// //   };

// //   const getUpcomingProgram = () => {
// //     const now = new Date();
// //     const nowTime = now.getHours() * 60 + now.getMinutes();
// //     for (const program of programs) {
// //       const startTime = convertTimeToMinutes(program.fromTime);
// //       if (startTime > nowTime) {
// //         return program;
// //       }
// //     }
// //     return null;
// //   };
// //   const upcomingProgram = getUpcomingProgram();

// //   useEffect(() => {
// //     const audio = audioRef.current;

// //     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
// //     const updateDuration = () => setDuration(audio.duration);

// //     audio.addEventListener('timeupdate', updateCurrentTime);
// //     audio.addEventListener('loadedmetadata', updateDuration);

// //     if (archiveUrl) {
// //       audio.src = archiveUrl;
// //     } else {
// //       audio.src = liveStreamUrl;
// //     }

// //     if (isPlaying) {
// //       audio.play();
// //     } else {
// //       audio.pause();
// //     }

// //     return () => {
// //       audio.removeEventListener('timeupdate', updateCurrentTime);
// //       audio.removeEventListener('loadedmetadata', updateDuration);
// //       audio.pause();
// //     };
// //   }, [isPlaying, liveStreamUrl, archiveUrl]);

// //   const handleTogglePlayPause = () => {
// //     dispatch(togglePlayPause());
// //   };

// //   const handleGoLive = () => {
// //     dispatch(setArchiveUrl(''));
// //     dispatch(updateCurrentRunningProgram({
// //       id: null,
// //       title: 'Live Program Title',
// //       rjName: 'RJ Name',
// //       showPic: 'https://path.to.live.show.pic',
// //     }));
// //     dispatch(togglePlayPause());
// //   };

// //   const formatTime = (seconds) => {
// //     const h = Math.floor(seconds / 3600);
// //     const m = Math.floor((seconds % 3600) / 60);
// //     const s = Math.floor(seconds % 60);
// //     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
// //   };

// //   const leftPosition = isCollapsed ? 'left-[1rem] md:left-[10rem]' : 'left-[20rem]';

// //   const handleCommentsClick = () => {
// //     navigate(`/streaming/${currentRunningProgram.id}`);
// //   };

// //   const handleVolumeClick = () => {
// //     setIsVolumeOpen(!isVolumeOpen);
// //   };

// //   const handleVolumeChange = (e) => {
// //     const newVolume = e.target.value;
// //     setVolume(newVolume);
// //     audioRef.current.volume = newVolume;
// //   };

// //   const truncateTitle = (title, maxLength) => {
// //     return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
// //   };

// //   return (
// //     <div
// //       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-2 md:p-4 h-16 md:h-14 fixed bottom-4 ${leftPosition} right-4 md:right-14 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
// //       onMouseEnter={() => setIsHovered(true)}
// //       onMouseLeave={() => setIsHovered(false)}
// //     >
// //       <div className="flex items-center flex-shrink-0">
// //         <img src={currentRunningProgram.showPic} alt="Show" className="hidden md:block w-10 h-10 object-cover rounded-lg mr-4" />
// //         <div className="flex flex-col md:flex-row md:items-center">
// //           <div className="md:hidden text-xs font-semibold flex flex-col">
// //             <div className="flex items-center">
// //               {truncateTitle(currentRunningProgram.title, 12)}
// //             </div>
// //             <span className="">{currentRunningProgram.rjName || ' '}</span>
// //           </div>
// //           <div className="hidden md:block">
// //             <h3 className="text-sm font-semibold flex items-center">
// //               {truncateTitle(currentRunningProgram.title, 25)}
// //               <img src={onAirImage} alt="On Air" className="ml-2 w-auto h-10" />
// //             </h3>
// //             <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || 'Test '}</p>
// //           </div>
// //         </div>
// //       </div>
// //       <div className="flex items-center space-x-4 md:flex-1 md:justify-center">
// //         <div className="relative">
// //           <button className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400" onClick={handleVolumeClick}>
// //             <FaVolumeUp />
// //           </button>
// //           {isVolumeOpen && (
// //             <input
// //               type="range"
// //               min="0"
// //               max="1"
// //               step="0.01"
// //               value={volume}
// //               onChange={handleVolumeChange}
// //               className="absolute bottom-20 right-px transform -translate-x-1/2"
// //               style={{ writingMode: 'bt-lr', transform: 'rotate(-90deg)' }}
// //             />
// //           )}
// //         </div>
// //         <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl">
// //           {isPlaying ? <FaPause /> : <FaPlay />}
// //         </button>
// //         {liveStreamUrl && currentRunningProgram.title !== 'Radio Surabahi Playlist' && !userData?.isGuest && (
// //           <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
// //             <FaComments />
// //           </button>
// //         )}
// //       </div>
// //       <div className="md:hidden flex items-center flex-shrink-0 space-x-2">
// //         <img src={onAirImage} alt="On Air" className="w-10 h-10" />
// //       </div>
// //       <div className="hidden md:flex items-center flex-shrink-0 space-x-2">
// //         {archiveUrl ? (
// //           <button onClick={handleGoLive} className="text-blue-500 hover:text-blue-700 flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
// //             <FaBroadcastTower className="mr-2" /> Go Live
// //           </button>
// //         ) : (
// //           upcomingProgram && (
// //             <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
// //               <span className="text-xs text-gray-600 dark:text-gray-400 ml-4">Upcoming: {truncateTitle(upcomingProgram.title, 25)}</span>
// //             </div>
// //           )
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default StreamingBar;

// // // src/components/StreamingBar.js
// // import React, { useEffect, useRef, useState } from 'react';
// // import { useSelector, useDispatch } from 'react-redux';
// // import { FaPlay, FaPause, FaVolumeUp, FaBroadcastTower, FaComments } from 'react-icons/fa';
// // import { togglePlayPause, setArchiveUrl } from '../redux/reducers/streamingBarSlice';
// // import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
// // import { useNavigate } from 'react-router-dom';
// // import onAirImage from '../assets/on air.png'; // Import the "On Air" image

// // const StreamingBar = ({ isCollapsed }) => {
// //   const dispatch = useDispatch();
// //   const navigate = useNavigate();
// //   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl } = useSelector((state) => state.streamingBar);
// //   const { programs } = useSelector((state) => state.programs);
// //   const { userData } = useSelector((state) => state.user);
// //   const audioRef = useRef(new Audio());
// //   const [currentTime, setCurrentTime] = useState(0);
// //   const [duration, setDuration] = useState(0);
// //   const [isHovered, setIsHovered] = useState(false);
// //   const [volume, setVolume] = useState(1); // State to manage volume
// //   const [isVolumeOpen, setIsVolumeOpen] = useState(false); // State to toggle volume slider visibility

// //   const convertTimeToMinutes = (timeString) => {
// //     const [time, modifier] = timeString.split(' ');
// //     let [hours, minutes] = time.split(':');
// //     hours = parseInt(hours, 10);
// //     minutes = parseInt(minutes, 10);

// //     if (modifier === 'PM' && hours !== 12) {
// //       hours += 12;
// //     } else if (modifier === 'AM' && hours === 12) {
// //       hours = 0;
// //     }

// //     return hours * 60 + minutes;
// //   };

// //   const getUpcomingProgram = () => {
// //     const now = new Date();
// //     const nowTime = now.getHours() * 60 + now.getMinutes();
// //     for (const program of programs) {
// //       const startTime = convertTimeToMinutes(program.fromTime);
// //       if (startTime > nowTime) {
// //         return program;
// //       }
// //     }
// //     return null;
// //   };
// //   const upcomingProgram = getUpcomingProgram();

// //   useEffect(() => {
// //     const audio = audioRef.current;

// //     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
// //     const updateDuration = () => setDuration(audio.duration);

// //     audio.addEventListener('timeupdate', updateCurrentTime);
// //     audio.addEventListener('loadedmetadata', updateDuration);

// //     if (archiveUrl) {
// //       audio.src = archiveUrl;
// //     } else {
// //       audio.src = liveStreamUrl;
// //     }

// //     if (isPlaying) {
// //       audio.play();
// //     } else {
// //       audio.pause();
// //     }

// //     return () => {
// //       audio.removeEventListener('timeupdate', updateCurrentTime);
// //       audio.removeEventListener('loadedmetadata', updateDuration);
// //       audio.pause();
// //     };
// //   }, [isPlaying, liveStreamUrl, archiveUrl]);

// //   const handleTogglePlayPause = () => {
// //     dispatch(togglePlayPause());
// //   };

// //   const handleGoLive = () => {
// //     dispatch(setArchiveUrl(''));
// //     dispatch(updateCurrentRunningProgram({
// //       id: null,
// //       title: 'Live Program Title',
// //       rjName: 'RJ Name',
// //       showPic: 'https://path.to.live.show.pic',
// //     }));
// //     dispatch(togglePlayPause());
// //   };

// //   const formatTime = (seconds) => {
// //     const h = Math.floor(seconds / 3600);
// //     const m = Math.floor((seconds % 3600) / 60);
// //     const s = Math.floor(seconds % 60);
// //     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
// //   };

// //   const leftPosition = isCollapsed ? 'left-[1rem] md:left-[10rem]' : 'left-[20rem]';

// //   const handleCommentsClick = () => {
// //     navigate(`/streaming/${currentRunningProgram.id}`);
// //   };

// //   const handleVolumeClick = () => {
// //     setIsVolumeOpen(!isVolumeOpen);
// //   };

// //   const handleVolumeChange = (e) => {
// //     const newVolume = e.target.value;
// //     setVolume(newVolume);
// //     audioRef.current.volume = newVolume;
// //   };

// //   const truncateTitle = (title, maxLength) => {
// //     return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
// //   };

// //   return (
// //     <div
// //       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-2 md:p-4 h-16 md:h-14 fixed bottom-4 ${leftPosition} right-4 md:right-14 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
// //       onMouseEnter={() => setIsHovered(true)}
// //       onMouseLeave={() => setIsHovered(false)}
// //     >
// //       <div className="flex items-center flex-shrink-0">
// //         <img src={currentRunningProgram.showPic} alt="Show" className="hidden md:block w-10 h-10 object-cover rounded-lg mr-4" />
// //         <div className="flex flex-col md:flex-row md:items-center md:justify-center md:space-x-4">
// //           <div className="flex flex-col md:items-start">
// //             <h3 className="text-sm font-semibold">
// //               <span className="md:hidden">
// //                 {truncateTitle(currentRunningProgram.title, 12)}
// //               </span>
// //               <span className="hidden md:inline">
// //                 {truncateTitle(currentRunningProgram.title, 25)}
// //               </span>
// //             </h3>
// //             <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
// //           </div>
// //           <img src={onAirImage} alt="On Air" className="hidden md:block w-auto h-16 ml-6 md:ml-2" />
// //         </div>
// //       </div>
// //       <div className="flex items-center space-x-4 md:flex-1 md:justify-center">
// //         <div className="relative">
// //           <button className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400" onClick={handleVolumeClick}>
// //             <FaVolumeUp />
// //           </button>
// //           {isVolumeOpen && (
// //             <input
// //               type="range"
// //               min="0"
// //               max="1"
// //               step="0.01"
// //               value={volume}
// //               onChange={handleVolumeChange}
// //               className="absolute bottom-20 right-px transform -translate-x-1/2"
// //               style={{ writingMode: 'bt-lr', transform: 'rotate(-90deg)' }}
// //             />
// //           )}
// //         </div>
// //         <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl">
// //           {isPlaying ? <FaPause /> : <FaPlay />}
// //         </button>
// //         {liveStreamUrl && currentRunningProgram.title !== 'Radio Surabahi Playlist' && !userData?.isGuest && (
// //           <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
// //             <FaComments />
// //           </button>
// //         )}
// //       </div>
// //       <div className="md:hidden flex items-center flex-shrink-0 space-x-2">
// //         <img src={onAirImage} alt="On Air" className="w-auto h-16" />
// //       </div>
// //       <div className="hidden md:flex items-center flex-shrink-0 space-x-2">
// //         {archiveUrl ? (
// //           <button onClick={handleGoLive} className="text-blue-500 hover:text-blue-700 flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
// //             <FaBroadcastTower className="mr-2" /> Go Live
// //           </button>
// //         ) : (
// //           upcomingProgram && (
// //             <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
// //               <span className="text-xs font-bold text-gray-600 dark:text-gray-400 ml-4">Upcoming: {truncateTitle(upcomingProgram.title, 25)}</span>
// //             </div>
// //           )
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default StreamingBar;


// // // src/components/StreamingBar.js
// // import React, { useEffect, useRef, useState } from 'react';
// // import { useSelector, useDispatch } from 'react-redux';
// // import { FaPlay, FaPause, FaVolumeUp, FaBroadcastTower, FaComments } from 'react-icons/fa';
// // import { togglePlayPause, setArchiveUrl } from '../redux/reducers/streamingBarSlice';
// // import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
// // import { useNavigate } from 'react-router-dom';
// // import onAirImage from '../assets/on air.png'; // Import the "On Air" image

// // const StreamingBar = ({ isCollapsed }) => {
// //   const dispatch = useDispatch();
// //   const navigate = useNavigate();
// //   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl, isPlayingArchive } = useSelector((state) => state.streamingBar);
// //   const { programs } = useSelector((state) => state.programs);
// //   const { userData } = useSelector((state) => state.user);
// //   const audioRef = useRef(new Audio());
// //   const [currentTime, setCurrentTime] = useState(0);
// //   const [duration, setDuration] = useState(0);
// //   const [isHovered, setIsHovered] = useState(false);
// //   const [volume, setVolume] = useState(1); // State to manage volume
// //   const [isVolumeOpen, setIsVolumeOpen] = useState(false); // State to toggle volume slider visibility

// //   const convertTimeToMinutes = (timeString) => {
// //     const [time, modifier] = timeString.split(' ');
// //     let [hours, minutes] = time.split(':');
// //     hours = parseInt(hours, 10);
// //     minutes = parseInt(minutes, 10);

// //     if (modifier === 'PM' && hours !== 12) {
// //       hours += 12;
// //     } else if (modifier === 'AM' && hours === 12) {
// //       hours = 0;
// //     }

// //     return hours * 60 + minutes;
// //   };

// //   const getUpcomingProgram = () => {
// //     const now = new Date();
// //     const nowTime = now.getHours() * 60 + now.getMinutes();
// //     for (const program of programs) {
// //       const startTime = convertTimeToMinutes(program.fromTime);
// //       if (startTime > nowTime) {
// //         return program;
// //       }
// //     }
// //     return null;
// //   };
// //   const upcomingProgram = getUpcomingProgram();

// //   useEffect(() => {
// //     const audio = audioRef.current;

// //     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
// //     const updateDuration = () => setDuration(audio.duration);

// //     audio.addEventListener('timeupdate', updateCurrentTime);
// //     audio.addEventListener('loadedmetadata', updateDuration);

// //     if (archiveUrl) {
// //       audio.src = archiveUrl;
// //     } else {
// //       audio.src = liveStreamUrl;
// //     }

// //     if (isPlaying) {
// //       audio.play();
// //     } else {
// //       audio.pause();
// //     }

// //     return () => {
// //       audio.removeEventListener('timeupdate', updateCurrentTime);
// //       audio.removeEventListener('loadedmetadata', updateDuration);
// //       audio.pause();
// //     };
// //   }, [isPlaying, liveStreamUrl, archiveUrl]);

// //   const handleTogglePlayPause = () => {
// //     dispatch(togglePlayPause());
// //   };

// //   const handleGoLive = () => {
// //     dispatch(setArchiveUrl(''));
// //     dispatch(updateCurrentRunningProgram({
// //       id: null,
// //       title: 'Live Program Title',
// //       rjName: 'RJ Name',
// //       showPic: 'https://path.to.live.show.pic',
// //     }));
// //     dispatch(togglePlayPause());
// //   };

// //   const formatTime = (seconds) => {
// //     const h = Math.floor(seconds / 3600);
// //     const m = Math.floor((seconds % 3600) / 60);
// //     const s = Math.floor(seconds % 60);
// //     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
// //   };

// //   const leftPosition = isCollapsed ? 'left-[1rem] md:left-[10rem]' : 'left-[20rem]';

// //   const handleCommentsClick = () => {
// //     navigate(`/streaming/${currentRunningProgram.id}`);
// //   };

// //   const handleVolumeClick = () => {
// //     setIsVolumeOpen(!isVolumeOpen);
// //   };

// //   const handleVolumeChange = (e) => {
// //     const newVolume = e.target.value;
// //     setVolume(newVolume);
// //     audioRef.current.volume = newVolume;
// //   };

// //   const truncateTitle = (title, maxLength) => {
// //     return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
// //   };

// //   return (
// //     <div
// //       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-2 md:p-4 h-16 md:h-14 fixed bottom-4 ${leftPosition} right-4 md:right-14 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
// //       onMouseEnter={() => setIsHovered(true)}
// //       onMouseLeave={() => setIsHovered(false)}
// //     >
// //       <div className="flex items-center flex-shrink-0">
// //         <img src={currentRunningProgram.showPic} alt="Show" className="hidden md:block w-10 h-10 object-cover rounded-lg mr-4" />
// //         <div className="flex flex-col md:flex-row md:items-center md:justify-center md:space-x-4">
// //           <div className="flex flex-col md:items-start">
// //             <h3 className="text-sm font-semibold">
// //               <span className="md:hidden">
// //                 {truncateTitle(currentRunningProgram.title, 12)}
// //               </span>
// //               <span className="hidden md:inline">
// //                 {truncateTitle(currentRunningProgram.title, 25)}
// //               </span>
// //             </h3>
// //             <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
// //           </div>
// //           <img src={onAirImage} alt="On Air" className="hidden md:block w-auto h-16 ml-6 md:ml-2" />
// //         </div>
// //       </div>
// //       <div className="flex items-center space-x-4 md:flex-1 md:justify-center">
// //         <div className="relative">
// //           <button className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400" onClick={handleVolumeClick}>
// //             <FaVolumeUp />
// //           </button>
// //           {isVolumeOpen && (
// //             <input
// //               type="range"
// //               min="0"
// //               max="1"
// //               step="0.01"
// //               value={volume}
// //               onChange={handleVolumeChange}
// //               className="absolute bottom-20 right-px transform -translate-x-1/2"
// //               style={{ writingMode: 'bt-lr', transform: 'rotate(-90deg)' }}
// //             />
// //           )}
// //         </div>
// //         <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl">
// //           {isPlaying ? <FaPause /> : <FaPlay />}
// //         </button>
// //         {liveStreamUrl && currentRunningProgram.title !== 'Radio Surabahi Playlist' && !userData?.isGuest && (
// //           <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
// //             <FaComments />
// //           </button>
// //         )}
// //       </div>
// //       <div className="md:hidden flex items-center flex-shrink-0 space-x-2">
// //         <img src={onAirImage} alt="On Air" className="w-auto h-16" />
// //       </div>
// //       <div className="hidden md:flex items-center flex-shrink-0 space-x-2">
// //         {archiveUrl ? (
// //           <button onClick={handleGoLive} className="text-blue-500 hover:text-blue-700 flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
// //             <FaBroadcastTower className="mr-2" /> Go Live
// //           </button>
// //         ) : (
// //           upcomingProgram && (
// //             <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
// //               <span className="text-xs font-bold text-gray-600 dark:text-gray-400 ml-4">Upcoming: {truncateTitle(upcomingProgram.title, 25)}</span>
// //             </div>
// //           )
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default StreamingBar;


// // // src/components/StreamingBar.js
// // import React, { useEffect, useRef, useState } from 'react';
// // import { useSelector, useDispatch } from 'react-redux';
// // import { FaPlay, FaPause, FaVolumeUp, FaComments } from 'react-icons/fa';
// // import { togglePlayPause, setArchiveUrl } from '../redux/reducers/streamingBarSlice';
// // import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
// // import { useNavigate } from 'react-router-dom';
// // import onAirImage from '../assets/on air.png'; // Import the "On Air" image
// // import goLiveImage from '../assets/Go live .png'; // Import the "Go Live" image

// // const StreamingBar = ({ isCollapsed }) => {
// //   const dispatch = useDispatch();
// //   const navigate = useNavigate();
// //   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl } = useSelector((state) => state.streamingBar);
// //   const { programs } = useSelector((state) => state.programs);
// //   const { userData } = useSelector((state) => state.user);
// //   const audioRef = useRef(new Audio());
// //   const [currentTime, setCurrentTime] = useState(0);
// //   const [duration, setDuration] = useState(0);
// //   const [isHovered, setIsHovered] = useState(false);
// //   const [volume, setVolume] = useState(1); // State to manage volume
// //   const [isVolumeOpen, setIsVolumeOpen] = useState(false); // State to toggle volume slider visibility

// //   const convertTimeToMinutes = (timeString) => {
// //     const [time, modifier] = timeString.split(' ');
// //     let [hours, minutes] = time.split(':');
// //     hours = parseInt(hours, 10);
// //     minutes = parseInt(minutes, 10);

// //     if (modifier === 'PM' && hours !== 12) {
// //       hours += 12;
// //     } else if (modifier === 'AM' && hours === 12) {
// //       hours = 0;
// //     }

// //     return hours * 60 + minutes;
// //   };

// //   const getUpcomingProgram = () => {
// //     const now = new Date();
// //     const nowTime = now.getHours() * 60 + now.getMinutes();
// //     for (const program of programs) {
// //       const startTime = convertTimeToMinutes(program.fromTime);
// //       if (startTime > nowTime) {
// //         return program;
// //       }
// //     }
// //     return null;
// //   };
// //   const upcomingProgram = getUpcomingProgram();

// //   useEffect(() => {
// //     const audio = audioRef.current;

// //     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
// //     const updateDuration = () => setDuration(audio.duration);

// //     audio.addEventListener('timeupdate', updateCurrentTime);
// //     audio.addEventListener('loadedmetadata', updateDuration);

// //     if (archiveUrl) {
// //       audio.src = archiveUrl;
// //     } else {
// //       audio.src = liveStreamUrl;
// //     }

// //     if (isPlaying) {
// //       audio.play();
// //     } else {
// //       audio.pause();
// //     }

// //     return () => {
// //       audio.removeEventListener('timeupdate', updateCurrentTime);
// //       audio.removeEventListener('loadedmetadata', updateDuration);
// //       audio.pause();
// //     };
// //   }, [isPlaying, liveStreamUrl, archiveUrl]);

// //   const handleTogglePlayPause = () => {
// //     dispatch(togglePlayPause());
// //   };

// //   const handleGoLive = () => {
// //     dispatch(setArchiveUrl(''));
// //     const liveProgram = {
// //       id: null,
// //       title: 'Live Program Title',
// //       rjName: 'RJ Name',
// //       showPic: 'https://path.to.live.show.pic',
// //     };
// //     dispatch(updateCurrentRunningProgram(liveProgram));
// //     dispatch(togglePlayPause());
// //   };

// //   const formatTime = (seconds) => {
// //     const h = Math.floor(seconds / 3600);
// //     const m = Math.floor((seconds % 3600) / 60);
// //     const s = Math.floor(seconds % 60);
// //     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
// //   };

// //   const leftPosition = isCollapsed ? 'left-[1rem] md:left-[10rem]' : 'left-[20rem]';

// //   const handleCommentsClick = () => {
// //     if (!archiveUrl) {
// //       navigate(`/streaming/${currentRunningProgram.id}`);
// //     }
// //   };

// //   const handleVolumeClick = () => {
// //     setIsVolumeOpen(!isVolumeOpen);
// //   };

// //   const handleVolumeChange = (e) => {
// //     const newVolume = e.target.value;
// //     setVolume(newVolume);
// //     audioRef.current.volume = newVolume;
// //   };

// //   const truncateTitle = (title, maxLength) => {
// //     return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
// //   };

// //   return (
// //     <div
// //       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-2 md:p-4 h-16 md:h-14 fixed bottom-4 ${leftPosition} right-4 md:right-14 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
// //       onMouseEnter={() => setIsHovered(true)}
// //       onMouseLeave={() => setIsHovered(false)}
// //     >
// //       <div className="flex items-center flex-shrink-0">
// //         <img src={currentRunningProgram.showPic} alt="Show" className="hidden md:block w-10 h-10 object-cover rounded-lg mr-4" />
// //         <div className="flex flex-col md:flex-row md:items-center md:justify-center md:space-x-4">
// //           <div className="flex flex-col md:items-start">
// //             <h3 className="text-sm font-semibold">
// //               <span className="md:hidden">
// //                 {truncateTitle(currentRunningProgram.title, 12)}
// //               </span>
// //               <span className="hidden md:inline">
// //                 {truncateTitle(currentRunningProgram.title, 25)}
// //               </span>
// //             </h3>
// //             <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
// //           </div>
// //           <img
// //             src={archiveUrl ? goLiveImage : onAirImage}
// //             alt={archiveUrl ? "Go Live" : "On Air"}
// //             className={`hidden md:block ${archiveUrl ? 'w-16 h-8' : 'w-auto h-16'} ml-6 md:ml-2 cursor-pointer`}
// //             onClick={archiveUrl ? handleGoLive : null}
// //           />
// //         </div>
// //       </div>
// //       <div className="flex items-center space-x-4 md:flex-1 md:justify-center">
// //         <div className="relative">
// //           <button className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400" onClick={handleVolumeClick}>
// //             <FaVolumeUp />
// //           </button>
// //           {isVolumeOpen && (
// //             <input
// //               type="range"
// //               min="0"
// //               max="1"
// //               step="0.01"
// //               value={volume}
// //               onChange={handleVolumeChange}
// //               className="absolute bottom-20 right-px transform -translate-x-1/2"
// //               style={{ writingMode: 'bt-lr', transform: 'rotate(-90deg)' }}
// //             />
// //           )}
// //         </div>
// //         <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl">
// //           {isPlaying ? <FaPause /> : <FaPlay />}
// //         </button>
// //         {!archiveUrl && liveStreamUrl && currentRunningProgram.title !== 'Radio Surabahi Playlist' && !userData?.isGuest && (
// //           <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
// //             <FaComments />
// //           </button>
// //         )}
// //       </div>
// //       <div className="md:hidden flex items-center flex-shrink-0 space-x-2">
// //         <img
// //           src={archiveUrl ? goLiveImage : onAirImage}
// //           alt={archiveUrl ? "Go Live" : "On Air"}
// //           className={`w-16 h-8 cursor-pointer ${archiveUrl ? 'w-20 h-8' : 'w-20 h-24'}`}
// //           onClick={archiveUrl ? handleGoLive : null}
// //         />
// //       </div>
// //       <div className="hidden md:flex items-center flex-shrink-0 space-x-2">
// //         {archiveUrl ? (
// //           <div className="flex items-center space-x-2">
// //             <input
// //               type="range"
// //               min="0"
// //               max={duration}
// //               value={currentTime}
// //               onChange={(e) => audioRef.current.currentTime = e.target.value}
// //               className="ml-4"
// //             />
// //             <span className="text-xs font-bold text-gray-600 dark:text-gray-400">{`${formatTime(currentTime)}/${formatTime(duration)}`}</span>
// //           </div>
// //         ) : (
// //           upcomingProgram && (
// //             <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
// //               <span className="text-xs font-bold text-gray-600 dark:text-gray-400 ml-4">Upcoming: {truncateTitle(upcomingProgram.title, 25)}</span>
// //             </div>
// //           )
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default StreamingBar;


// import React, { useEffect, useRef, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { FaPlay, FaPause, FaVolumeUp, FaComments, FaTimes } from 'react-icons/fa';
// import { togglePlayPause, setArchiveUrl } from '../redux/reducers/streamingBarSlice';
// import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
// import { useNavigate } from 'react-router-dom';
// import onAirImage from '../assets/on air.png'; // Import the "On Air" image
// import goLiveImage from '../assets/Go live .png'; // Import the "Go Live" image

// const StreamingBar = ({ isCollapsed }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl } = useSelector((state) => state.streamingBar);
//   const { programs } = useSelector((state) => state.programs);
//   const { userData } = useSelector((state) => state.user);
//   const audioRef = useRef(new Audio());
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [isHovered, setIsHovered] = useState(false);
//   const [volume, setVolume] = useState(1); // State to manage volume
//   const [isVolumeOpen, setIsVolumeOpen] = useState(false); // State to toggle volume slider visibility
//   const [showRegisterPopup, setShowRegisterPopup] = useState(false); // State to toggle registration popup

//   const convertTimeToMinutes = (timeString) => {
//     const [time, modifier] = timeString.split(' ');
//     let [hours, minutes] = time.split(':');
//     hours = parseInt(hours, 10);
//     minutes = parseInt(minutes, 10);

//     if (modifier === 'PM' && hours !== 12) {
//       hours += 12;
//     } else if (modifier === 'AM' && hours === 12) {
//       hours = 0;
//     }

//     return hours * 60 + minutes;
//   };

//   const getUpcomingProgram = () => {
//     const now = new Date();
//     const nowTime = now.getHours() * 60 + now.getMinutes();
//     for (const program of programs) {
//       const startTime = convertTimeToMinutes(program.fromTime);
//       if (startTime > nowTime) {
//         return program;
//       }
//     }
//     return null;
//   };
//   const upcomingProgram = getUpcomingProgram();

//   useEffect(() => {
//     const audio = audioRef.current;

//     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
//     const updateDuration = () => setDuration(audio.duration);

//     audio.addEventListener('timeupdate', updateCurrentTime);
//     audio.addEventListener('loadedmetadata', updateDuration);

//     if (archiveUrl) {
//       audio.src = archiveUrl;
//     } else {
//       audio.src = liveStreamUrl;
//     }

//     if (isPlaying) {
//       audio.play();
//     } else {
//       audio.pause();
//     }

//     return () => {
//       audio.removeEventListener('timeupdate', updateCurrentTime);
//       audio.removeEventListener('loadedmetadata', updateDuration);
//       audio.pause();
//     };
//   }, [isPlaying, liveStreamUrl, archiveUrl]);

//   const handleTogglePlayPause = () => {
//     dispatch(togglePlayPause());
//   };

//   const handleGoLive = () => {
//     dispatch(setArchiveUrl(''));
//     const liveProgram = {
//       id: null,
//       title: 'Live Program Title',
//       rjName: 'RJ Name',
//       showPic: 'https://path.to.live.show.pic',
//     };
//     dispatch(updateCurrentRunningProgram(liveProgram));
//     dispatch(togglePlayPause());
//   };

//   const formatTime = (seconds) => {
//     const h = Math.floor(seconds / 3600);
//     const m = Math.floor((seconds % 3600) / 60);
//     const s = Math.floor(seconds % 60);
//     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
//   };

//   const leftPosition = isCollapsed ? 'left-[1rem] md:left-[10rem]' : 'left-[20rem]';

//   const handleCommentsClick = () => {
//     if (userData?.isGuest) {
//       setShowRegisterPopup(true);
//     } else if (!archiveUrl) {
//       navigate(`/streaming/${currentRunningProgram.id}`);
//     }
//   };

//   const handleVolumeClick = () => {
//     setIsVolumeOpen(!isVolumeOpen);
//   };

//   const handleVolumeChange = (e) => {
//     const newVolume = e.target.value;
//     setVolume(newVolume);
//     audioRef.current.volume = newVolume;
//   };

//   const truncateTitle = (title, maxLength) => {
//     return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
//   };

//   const closeRegisterPopup = () => {
//     setShowRegisterPopup(false);
//   };

//   const handleLoginClick = () => {
//     navigate('/login');
//     setShowRegisterPopup(false);
//   };

//   const handleSignupClick = () => {
//     navigate('/signup');
//     setShowRegisterPopup(false);
//   };

//   return (
//     <div
//       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-2 md:p-4 h-16 md:h-14 fixed bottom-4 ${leftPosition} right-4 md:right-14 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       {showRegisterPopup && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
//             <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
//             <div className="flex space-x-4 justify-center">
//               <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleLoginClick}>Login</button>
//               <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleSignupClick}>Signup</button>
//             </div>
//             <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//       <div className="flex items-center flex-shrink-0">
//         <img src={currentRunningProgram.showPic} alt="Show" className="hidden md:block w-10 h-10 object-cover rounded-lg mr-4" />
//         <div className="flex flex-col md:flex-row md:items-center md:justify-center md:space-x-4">
//           <div className="flex flex-col md:items-start">
//             <h3 className="text-sm font-semibold">
//               <span className="md:hidden">
//                 {truncateTitle(currentRunningProgram.title, 12)}
//               </span>
//               <span className="hidden md:inline">
//                 {truncateTitle(currentRunningProgram.title, 25)}
//               </span>
//             </h3>
//             <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
//           </div>
//           <img
//             src={archiveUrl ? goLiveImage : onAirImage}
//             alt={archiveUrl ? "Go Live" : "On Air"}
//             className={`hidden md:block ${archiveUrl ? 'w-16 h-8' : 'w-auto h-16'} ml-6 md:ml-2 cursor-pointer`}
//             onClick={archiveUrl ? handleGoLive : null}
//           />
//         </div>
//       </div>
//       <div className="flex items-center space-x-4 md:flex-1 md:justify-center">
//         <div className="relative">
//           <button className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400" onClick={handleVolumeClick}>
//             <FaVolumeUp />
//           </button>
//           {isVolumeOpen && (
//             <input
//               type="range"
//               min="0"
//               max="1"
//               step="0.01"
//               value={volume}
//               onChange={handleVolumeChange}
//               className="absolute bottom-20 right-px transform -translate-x-1/2"
//               style={{ writingMode: 'bt-lr', transform: 'rotate(-90deg)' }}
//             />
//           )}
//         </div>
//         <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl">
//           {isPlaying ? <FaPause /> : <FaPlay />}
//         </button>
//         <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
//           <FaComments />
//         </button>
//       </div>
//       <div className="md:hidden flex items-center flex-shrink-0 space-x-2">
//         <img
//           src={archiveUrl ? goLiveImage : onAirImage}
//           alt={archiveUrl ? "Go Live" : "On Air"}
//           className={`w-16 h-20 cursor-pointer ${archiveUrl ? 'w-20 h-8' : 'w-20 h-24'}`}
//           onClick={archiveUrl ? handleGoLive : null}
//         />
//       </div>
//       <div className="hidden md:flex items-center flex-shrink-0 space-x-2">
//         {archiveUrl ? (
//           <div className="flex items-center space-x-2">
//             <input
//               type="range"
//               min="0"
//               max={duration}
//               value={currentTime}
//               onChange={(e) => audioRef.current.currentTime = e.target.value}
//               className="ml-4"
//             />
//             <span className="text-xs font-bold text-gray-600 dark:text-gray-400">{`${formatTime(currentTime)}/${formatTime(duration)}`}</span>
//           </div>
//         ) : (
//           upcomingProgram && (
//             <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
//               <span className="text-xs font-bold text-gray-600 dark:text-gray-400 ml-4">Upcoming: {truncateTitle(upcomingProgram.title, 25)}</span>
//             </div>
//           )
//         )}
//       </div>
//     </div>
//   );
// };

// export default StreamingBar;


// import React, { useEffect, useRef, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { FaPlay, FaPause, FaVolumeUp, FaComments, FaTimes } from 'react-icons/fa';
// import { togglePlayPause, setArchiveUrl, setSelectedCity } from '../redux/reducers/streamingBarSlice';
// import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
// import { useNavigate } from 'react-router-dom';
// import onAirImage from '../assets/on air.png';
// import goLiveImage from '../assets/Go live .png';

// const StreamingBar = ({ isCollapsed }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl, streamingURLs, selectedCity } = useSelector((state) => state.streamingBar);
//   const { programs } = useSelector((state) => state.programs);
//   const { userData } = useSelector((state) => state.user);
//   const audioRef = useRef(new Audio());
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [isHovered, setIsHovered] = useState(false);
//   const [volume, setVolume] = useState(1);
//   const [isVolumeOpen, setIsVolumeOpen] = useState(false);
//   const [showRegisterPopup, setShowRegisterPopup] = useState(false);

//   const convertTimeToMinutes = (timeString) => {
//     const [time, modifier] = timeString.split(' ');
//     let [hours, minutes] = time.split(':');
//     hours = parseInt(hours, 10);
//     minutes = parseInt(minutes, 10);

//     if (modifier === 'PM' && hours !== 12) {
//       hours += 12;
//     } else if (modifier === 'AM' && hours === 12) {
//       hours = 0;
//     }

//     return hours * 60 + minutes;
//   };

//   const getUpcomingProgram = () => {
//     const now = new Date();
//     const nowTime = now.getHours() * 60 + now.getMinutes();
//     for (const program of programs) {
//       const startTime = convertTimeToMinutes(program.fromTime);
//       if (startTime > nowTime) {
//         return program;
//       }
//     }
//     return null;
//   };

//   const upcomingProgram = getUpcomingProgram();

//   useEffect(() => {
//     const audio = audioRef.current;

//     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
//     const updateDuration = () => setDuration(audio.duration);

//     audio.addEventListener('timeupdate', updateCurrentTime);
//     audio.addEventListener('loadedmetadata', updateDuration);

//     if (archiveUrl) {
//       audio.src = archiveUrl;
//     } else {
//       audio.src = liveStreamUrl;
//     }

//     if (isPlaying) {
//       audio.play();
//     } else {
//       audio.pause();
//     }

//     return () => {
//       audio.removeEventListener('timeupdate', updateCurrentTime);
//       audio.removeEventListener('loadedmetadata', updateDuration);
//       audio.pause();
//     };
//   }, [isPlaying, liveStreamUrl, archiveUrl]);

//   const handleTogglePlayPause = () => {
//     dispatch(togglePlayPause());
//   };

//   const handleGoLive = () => {
//     dispatch(setArchiveUrl(''));
//     const liveProgram = {
//       id: null,
//       title: 'Live Program Title',
//       rjName: 'RJ Name',
//       showPic: 'https://path.to.live.show.pic',
//     };
//     dispatch(updateCurrentRunningProgram(liveProgram));
//     dispatch(togglePlayPause());
//   };

//   const formatTime = (seconds) => {
//     const h = Math.floor(seconds / 3600);
//     const m = Math.floor((seconds % 3600) / 60);
//     const s = Math.floor(seconds % 60);
//     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
//   };

//   const leftPosition = isCollapsed ? 'left-[1rem] md:left-[10rem]' : 'left-[20rem]';

//   const handleCommentsClick = () => {
//     if (userData?.isGuest) {
//       setShowRegisterPopup(true);
//     } else if (!archiveUrl) {
//       navigate(`/streaming/${currentRunningProgram.id}`);
//     }
//   };

//   const handleVolumeClick = () => {
//     setIsVolumeOpen(!isVolumeOpen);
//   };

//   const handleVolumeChange = (e) => {
//     const newVolume = e.target.value;
//     setVolume(newVolume);
//     audioRef.current.volume = newVolume;
//   };

//   const truncateTitle = (title, maxLength) => {
//     return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
//   };

//   const closeRegisterPopup = () => {
//     setShowRegisterPopup(false);
//   };

//   const handleLoginClick = () => {
//     navigate('/login');
//     setShowRegisterPopup(false);
//   };

//   const handleSignupClick = () => {
//     navigate('/signup');
//     setShowRegisterPopup(false);
//   };

//   const handleCitySelect = (city) => {
//     dispatch(setSelectedCity(city));
//     dispatch(togglePlayPause()); // Automatically play the selected city's stream
//   };

//   const defaultCity = streamingURLs.find(city => city.streamStatus === 'default');
//   const activeCity = streamingURLs.find(city => city.streamStatus === 'active');
//   const comingSoonCity = streamingURLs.find(city => city.streamStatus === 'coming soon');
//   const otherCities = streamingURLs.filter(city => city.streamStatus !== 'default' && city.streamStatus !== 'active' && city.streamStatus !== 'coming soon');

//   return (
//     <div
//       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-2 md:p-4 h-16 md:h-14 fixed bottom-4 ${leftPosition} right-4 md:right-14 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       {showRegisterPopup && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
//             <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
//             <div className="flex space-x-4 justify-center">
//               <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleLoginClick}>Login</button>
//               <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleSignupClick}>Signup</button>
//             </div>
//             <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//       <div className="flex items-center flex-shrink-0">
//         <img src={currentRunningProgram.showPic} alt="Show" className="hidden md:block w-10 h-10 object-cover rounded-lg mr-4" />
//         <div className="flex flex-col md:flex-row md:items-center md:justify-center md:space-x-4">
//           <div className="flex flex-col md:items-start">
//             <h3 className="text-sm font-semibold">
//               <span className="md:hidden">
//                 {truncateTitle(currentRunningProgram.title, 12)}
//               </span>
//               <span className="hidden md:inline">
//                 {truncateTitle(currentRunningProgram.title, 25)}
//               </span>
//             </h3>
//             <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
//           </div>
//           <img
//             src={archiveUrl ? goLiveImage : onAirImage}
//             alt={archiveUrl ? "Go Live" : "On Air"}
//             className={`hidden md:block ${archiveUrl ? 'w-16 h-8' : 'w-auto h-16'} ml-6 md:ml-2 cursor-pointer`}
//             onClick={archiveUrl ? handleGoLive : null}
//           />
//         </div>
//       </div>
//       <div className="flex items-center space-x-4 md:flex-1 md:justify-center">
//         <div className="relative">
//           <button className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400" onClick={handleVolumeClick}>
//             <FaVolumeUp />
//           </button>
//           {isVolumeOpen && (
//             <input
//               type="range"
//               min="0"
//               max="1"
//               step="0.01"
//               value={volume}
//               onChange={handleVolumeChange}
//               className="absolute bottom-20 right-px transform -translate-x-1/2"
//               style={{ writingMode: 'bt-lr', transform: 'rotate(-90deg)' }}
//             />
//           )}
//         </div>
//         <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl">
//           {isPlaying ? <FaPause /> : <FaPlay />}
//         </button>
//         <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
//           <FaComments />
//         </button>
//       </div>
//       <div className="md:hidden flex items-center flex-shrink-0 space-x-2">
//         <img
//           src={archiveUrl ? goLiveImage : onAirImage}
//           alt={archiveUrl ? "Go Live" : "On Air"}
//           className={`w-16 h-20 cursor-pointer ${archiveUrl ? 'w-20 h-8' : 'w-20 h-24'}`}
//           onClick={archiveUrl ? handleGoLive : null}
//         />
//       </div>
//       <div className="hidden md:flex items-center flex-shrink-0 space-x-2">
//         {archiveUrl ? (
//           <div className="flex items-center space-x-2">
//             <input
//               type="range"
//               min="0"
//               max={duration}
//               value={currentTime}
//               onChange={(e) => audioRef.current.currentTime = e.target.value}
//               className="ml-4"
//             />
//             <span className="text-xs font-bold text-gray-600 dark:text-gray-400">{`${formatTime(currentTime)}/${formatTime(duration)}`}</span>
//           </div>
//         ) : (
//           upcomingProgram && (
//             <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
//               <span className="text-xs font-bold text-gray-600 dark:text-gray-400 ml-4">Upcoming: {truncateTitle(upcomingProgram.title, 25)}</span>
//             </div>
//           )
//         )}
//       </div>
//     </div>
//   );
// };

// // export default StreamingBar;
// import React, { useEffect, useRef, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { FaPlay, FaPause, FaPhone, FaComments, FaTimes } from 'react-icons/fa';
// import { togglePlayPause, setArchiveUrl, setSelectedCity } from '../redux/reducers/streamingBarSlice';
// import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
// import { useNavigate } from 'react-router-dom';
// import onAirImage from '../assets/on air.png';
// import goLiveImage from '../assets/Go live .png';

// const StreamingBar = ({ isCollapsed }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl, streamingURLs, selectedCity } = useSelector((state) => state.streamingBar);
//   const { programs } = useSelector((state) => state.programs);
//   const { userData } = useSelector((state) => state.user);
//   const audioRef = useRef(new Audio());
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [isHovered, setIsHovered] = useState(false);
//   const [showRegisterPopup, setShowRegisterPopup] = useState(false);

//   const convertTimeToMinutes = (timeString) => {
//     const [time, modifier] = timeString.split(' ');
//     let [hours, minutes] = time.split(':');
//     hours = parseInt(hours, 10);
//     minutes = parseInt(minutes, 10);

//     if (modifier === 'PM' && hours !== 12) {
//       hours += 12;
//     } else if (modifier === 'AM' && hours === 12) {
//       hours = 0;
//     }

//     return hours * 60 + minutes;
//   };

//   const getUpcomingProgram = () => {
//     const now = new Date();
//     const nowTime = now.getHours() * 60 + now.getMinutes();
//     for (const program of programs) {
//       const startTime = convertTimeToMinutes(program.fromTime);
//       if (startTime > nowTime) {
//         return program;
//       }
//     }
//     return null;
//   };

//   const upcomingProgram = getUpcomingProgram();

//   useEffect(() => {
//     const audio = audioRef.current;

//     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
//     const updateDuration = () => setDuration(audio.duration);

//     audio.addEventListener('timeupdate', updateCurrentTime);
//     audio.addEventListener('loadedmetadata', updateDuration);

//     if (archiveUrl) {
//       audio.src = archiveUrl;
//     } else {
//       audio.src = liveStreamUrl;
//     }

//     if (isPlaying) {
//       audio.play();
//     } else {
//       audio.pause();
//     }

//     return () => {
//       audio.removeEventListener('timeupdate', updateCurrentTime);
//       audio.removeEventListener('loadedmetadata', updateDuration);
//       audio.pause();
//     };
//   }, [isPlaying, liveStreamUrl, archiveUrl]);

//   const handleTogglePlayPause = () => {
//     dispatch(togglePlayPause());
//   };

//   const handleGoLive = () => {
//     dispatch(setArchiveUrl(''));
//     const liveProgram = {
//       id: null,
//       title: 'Live Program Title',
//       rjName: 'RJ Name',
//       showPic: 'https://path.to.live.show.pic',
//     };
//     dispatch(updateCurrentRunningProgram(liveProgram));
//     dispatch(togglePlayPause());
//   };

//   const formatTime = (seconds) => {
//     const h = Math.floor(seconds / 3600);
//     const m = Math.floor((seconds % 3600) / 60);
//     const s = Math.floor(seconds % 60);
//     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
//   };

//   const leftPosition = isCollapsed ? 'left-[1rem] md:left-36' : 'left-[20rem]';

//   const handleCommentsClick = () => {
//     if (userData?.isGuest) {
//       setShowRegisterPopup(true);
//     } else if (!archiveUrl) {
//       navigate(`/streaming/${currentRunningProgram.id}`);
//     }
//   };

//   const truncateTitle = (title, maxLength) => {
//     return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
//   };

//   const closeRegisterPopup = () => {
//     setShowRegisterPopup(false);
//   };

//   const handleLoginClick = () => {
//     navigate('/login');
//     setShowRegisterPopup(false);
//   };

//   const handleSignupClick = () => {
//     navigate('/signup');
//     setShowRegisterPopup(false);
//   };

//   const handleCitySelect = (city) => {
//     dispatch(setSelectedCity(city));
//     dispatch(togglePlayPause()); // Automatically play the selected city's stream
//   };

//   const defaultCity = streamingURLs.find(city => city.streamStatus === 'default');
//   const activeCity = streamingURLs.find(city => city.streamStatus === 'active');
//   const comingSoonCity = streamingURLs.find(city => city.streamStatus === 'coming soon');
//   const otherCities = streamingURLs.filter(city => city.streamStatus !== 'default' && city.streamStatus !== 'active' && city.streamStatus !== 'coming soon');

//   return (
//     <div
//       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-2 md:p-4 h-16 md:h-14 fixed bottom-4 ${leftPosition} right-4 md:right-16 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       {showRegisterPopup && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
//             <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
//             <div className="flex space-x-4 justify-center">
//               <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleLoginClick}>Login</button>
//               <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleSignupClick}>Signup</button>
//             </div>
//             <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//       <div className="flex items-center flex-shrink-0">
//         <img src={currentRunningProgram.showPic} alt="Show" className="hidden md:block w-10 h-10 object-cover rounded-lg mr-4" />
//         <div className="flex flex-col md:flex-row md:items-center md:justify-center md:space-x-4">
//           <div className="flex flex-col md:items-start">
//             <h3 className="text-sm font-semibold">
//               <span className="md:hidden">
//                 {truncateTitle(currentRunningProgram.title, 12)}
//               </span>
//               <span className="hidden md:inline">
//                 {truncateTitle(currentRunningProgram.title, 25)}
//               </span>
//             </h3>
//             <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
//           </div>
//           <img
//             src={archiveUrl ? goLiveImage : onAirImage}
//             alt={archiveUrl ? "Go Live" : "On Air"}
//             className={`hidden md:block ${archiveUrl ? 'w-16 h-8' : 'w-auto h-16'} ml-6 md:ml-2 cursor-pointer`}
//             onClick={archiveUrl ? handleGoLive : null}
//           />
//         </div>
//       </div>
//       <div className="flex items-center space-x-4 md:flex-1 md:justify-center">
//         <div
//           className="relative flex items-center"
//           onMouseEnter={() => setIsHovered(true)}
//           onMouseLeave={() => setIsHovered(false)}
//         >
//           <FaPhone className={`transition-transform duration-300 ${!isHovered ? 'transform translate-x-28' : 'translate-x-0'}`} />
//           <span className={`transition-opacity duration-300 ml-2 text-gray-800 dark:text-gray-200 font-bold ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
//             972-636-1966
//           </span>
//         </div>
//         <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl">
//           {isPlaying ? <FaPause /> : <FaPlay />}
//         </button>
//         <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
//           <FaComments />
//         </button>
//       </div>
//       <div className="md:hidden flex items-center flex-shrink-0 space-x-2">
//         <img
//           src={archiveUrl ? goLiveImage : onAirImage}
//           alt={archiveUrl ? "Go Live" : "On Air"}
//           className={`w-16 h-20 cursor-pointer ${archiveUrl ? 'w-20 h-8' : 'w-20 h-24'}`}
//           onClick={archiveUrl ? handleGoLive : null}
//         />
//       </div>
//       <div className="hidden md:flex items-center flex-shrink-0 space-x-2">
//         {archiveUrl ? (
//           <div className="flex items-center space-x-2">
//             <input
//               type="range"
//               min="0"
//               max={duration}
//               value={currentTime}
//               onChange={(e) => audioRef.current.currentTime = e.target.value}
//               className="ml-4"
//             />
//             <span className="text-xs font-bold text-gray-600 dark:text-gray-400">{`${formatTime(currentTime)}/${formatTime(duration)}`}</span>
//           </div>
//         ) : (
//           upcomingProgram && (
//             <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
//               <span className="text-xs font-bold text-gray-600 dark:text-gray-400 ml-4">Upcoming: {truncateTitle(upcomingProgram.title, 25)}</span>
//             </div>
//           )
//         )}
//       </div>
//     </div>
//   );
// };

// export default StreamingBar;

// import React, { useEffect, useRef, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { FaPlay, FaPause, FaPhone, FaComments } from 'react-icons/fa';
// import { togglePlayPause, setArchiveUrl, setSelectedCity } from '../redux/reducers/streamingBarSlice';
// import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
// import { useNavigate } from 'react-router-dom';
// import onAirImage from '../assets/on air.png';
// import goLiveImage from '../assets/Go live .png';

// const StreamingBar = ({ isCollapsed }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl, streamingURLs, selectedCity } = useSelector((state) => state.streamingBar);
//   const { programs } = useSelector((state) => state.programs);
//   const { userData } = useSelector((state) => state.user);
//   const audioRef = useRef(new Audio());
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [isHovered, setIsHovered] = useState(false);
//   const [showRegisterPopup, setShowRegisterPopup] = useState(false);

//   const convertTimeToMinutes = (timeString) => {
//     const [time, modifier] = timeString.split(' ');
//     let [hours, minutes] = time.split(':');
//     hours = parseInt(hours, 10);
//     minutes = parseInt(minutes, 10);

//     if (modifier === 'PM' && hours !== 12) {
//       hours += 12;
//     } else if (modifier === 'AM' && hours === 12) {
//       hours = 0;
//     }

//     return hours * 60 + minutes;
//   };

//   const getUpcomingProgram = () => {
//     const now = new Date();
//     const nowTime = now.getHours() * 60 + now.getMinutes();
//     for (const program of programs) {
//       const startTime = convertTimeToMinutes(program.fromTime);
//       if (startTime > nowTime) {
//         return program;
//       }
//     }
//     return null;
//   };

//   const upcomingProgram = getUpcomingProgram();

//   useEffect(() => {
//     const audio = audioRef.current;

//     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
//     const updateDuration = () => setDuration(audio.duration);

//     audio.addEventListener('timeupdate', updateCurrentTime);
//     audio.addEventListener('loadedmetadata', updateDuration);

//     if (archiveUrl) {
//       audio.src = archiveUrl;
//     } else {
//       audio.src = liveStreamUrl;
//     }

//     if (isPlaying) {
//       audio.play();
//     } else {
//       audio.pause();
//     }

//     return () => {
//       audio.removeEventListener('timeupdate', updateCurrentTime);
//       audio.removeEventListener('loadedmetadata', updateDuration);
//       audio.pause();
//     };
//   }, [isPlaying, liveStreamUrl, archiveUrl]);

//   const handleTogglePlayPause = () => {
//     dispatch(togglePlayPause());
//   };

//   const handleGoLive = () => {
//     dispatch(setArchiveUrl(''));
//     const liveProgram = {
//       id: null,
//       title: 'Live Program Title',
//       rjName: 'RJ Name',
//       showPic: 'https://path.to.live.show.pic',
//     };
//     dispatch(updateCurrentRunningProgram(liveProgram));
//     dispatch(togglePlayPause());
//   };

//   const formatTime = (seconds) => {
//     const h = Math.floor(seconds / 3600);
//     const m = Math.floor((seconds % 3600) / 60);
//     const s = Math.floor(seconds % 60);
//     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
//   };

//   const leftPosition = isCollapsed ? 'left-[1rem] md:left-36' : 'left-[20rem]';

//   const handleCommentsClick = () => {
//     if (userData?.isGuest) {
//       setShowRegisterPopup(true);
//     } else if (!archiveUrl) {
//       navigate(`/streaming/${currentRunningProgram.id}`);
//     }
//   };

//   const truncateTitle = (title, maxLength) => {
//     return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
//   };

//   const closeRegisterPopup = () => {
//     setShowRegisterPopup(false);
//   };

//   const handleLoginClick = () => {
//     navigate('/login');
//     setShowRegisterPopup(false);
//   };

//   const handleSignupClick = () => {
//     navigate('/signup');
//     setShowRegisterPopup(false);
//   };

//   const handleCitySelect = (city) => {
//     dispatch(setSelectedCity(city));
//     dispatch(togglePlayPause()); // Automatically play the selected city's stream
//   };

//   const defaultCity = streamingURLs.find(city => city.streamStatus === 'default');
//   const activeCity = streamingURLs.find(city => city.streamStatus === 'active');
//   const comingSoonCity = streamingURLs.find(city => city.streamStatus === 'coming soon');
//   const otherCities = streamingURLs.filter(city => city.streamStatus !== 'default' && city.streamStatus !== 'active' && city.streamStatus !== 'coming soon');

//   return (
//     <div
//       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-2 md:p-4 h-16 md:h-14 fixed bottom-4 ${leftPosition} right-4 md:right-16 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       {showRegisterPopup && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
//             <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
//             <div className="flex space-x-4 justify-center">
//               <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleLoginClick}>Login</button>
//               <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleSignupClick}>Signup</button>
//             </div>
//             <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//       <div className="flex items-center flex-shrink-0">
//         <img src={currentRunningProgram.showPic} alt="Show" className="hidden md:block w-10 h-10 object-cover rounded-lg mr-4" />
//         <div className="flex flex-col md:flex-row md:items-center md:justify-center md:space-x-4">
//           <div className="flex flex-col md:items-start">
//             <h3 className="text-sm font-semibold">
//               <span className="md:hidden">
//                 {truncateTitle(currentRunningProgram.title, 12)}
//               </span>
//               <span className="hidden md:inline">
//                 {truncateTitle(currentRunningProgram.title, 25)}
//               </span>
//             </h3>
//             <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
//           </div>
//           <img
//             src={archiveUrl ? goLiveImage : onAirImage}
//             alt={archiveUrl ? "Go Live" : "On Air"}
//             className={`hidden md:block ${archiveUrl ? 'w-16 h-8' : 'w-auto h-16'} ml-6 md:ml-2 cursor-pointer`}
//             onClick={archiveUrl ? handleGoLive : null}
//           />
//         </div>
//       </div>
//       <div className="flex-1 flex items-center justify-center space-x-4 transform -translate-x-16">
//         <div
//           className="relative flex items-center"
//           onMouseEnter={() => setIsHovered(true)}
//           onMouseLeave={() => setIsHovered(false)}
//         >
//           <FaPhone className={`transition-transform duration-300 ${!isHovered ? 'transform translate-x-28' : 'translate-x-0'}`} />
//           <span className={`transition-opacity duration-300 ml-2 text-gray-800 dark:text-gray-200 font-bold ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
//             972-636-1966
//           </span>
//         </div>
//         <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl">
//           {isPlaying ? <FaPause /> : <FaPlay />}
//         </button>
//         <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
//           <FaComments />
//         </button>
//       </div>
//       <div className="flex items-center flex-shrink-0">
//         <div className="hidden md:flex items-center space-x-2">
//           {archiveUrl ? (
//             <div className="flex items-center space-x-2">
//               <input
//                 type="range"
//                 min="0"
//                 max={duration}
//                 value={currentTime}
//                 onChange={(e) => audioRef.current.currentTime = e.target.value}
//                 className="ml-4"
//               />
//               <span className="text-xs font-bold text-gray-600 dark:text-gray-400">{`${formatTime(currentTime)}/${formatTime(duration)}`}</span>
//             </div>
//           ) : (
//             upcomingProgram && (
//               <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
//                 <span className="text-xs font-bold text-gray-600 dark:text-gray-400 ml-4">Upcoming: {truncateTitle(upcomingProgram.title, 25)}</span>
//               </div>
//             )
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StreamingBar;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaPlay, FaPause, FaPhone, FaComments } from 'react-icons/fa';
import { goToLiveStream } from '../redux/reducers/streamingBarSlice'; // Ensure goToLiveStream is imported
import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
import { useNavigate } from 'react-router-dom';
import { useAudioPlayer } from '../context/AudioPlayerContext'; // Import the audio player context
import onAirImage from '../assets/on air.png';
import goLiveImage from '../assets/Go live .png';

const StreamingBar = ({ isCollapsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentRunningProgram, archiveUrl, streamingURLs, selectedCity } = useSelector((state) => state.streamingBar);
  const { programs } = useSelector((state) => state.programs);
  const { userData } = useSelector((state) => state.user);
  const { currentTime, duration, isPlaying, handleTogglePlayPause, handleTimeChange, formatTime } = useAudioPlayer(); // Destructure audio context values
  const [isHovered, setIsHovered] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);

  const convertTimeToMinutes = (timeString) => {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  const getUpcomingProgram = () => {
    const now = new Date();
    const nowTime = now.getHours() * 60 + now.getMinutes();
    for (const program of programs) {
      const startTime = convertTimeToMinutes(program.fromTime);
      if (startTime > nowTime) {
        return program;
      }
    }
    return null;
  };

  const upcomingProgram = getUpcomingProgram();

  const handleGoLive = () => {
    dispatch(goToLiveStream());
    const liveProgram = {
      id: null,
      title: 'Live Program Title',
      rjName: 'RJ Name',
      showPic: 'https://path.to.live.show.pic',
    };
    dispatch(updateCurrentRunningProgram(liveProgram));
    handleTogglePlayPause(); // Play the live stream
  };

  const leftPosition = isCollapsed ? 'left-[1rem] md:left-36' : 'left-[20rem]';

  const handleCommentsClick = () => {
    if (userData?.isGuest) {
      setShowRegisterPopup(true);
    } else if (!archiveUrl) {
      navigate(`/streaming/${currentRunningProgram.id}`);
    }
  };

  const truncateTitle = (title, maxLength) => {
    return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
  };

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
  };

  const handleLoginClick = () => {
    navigate('/login');
    setShowRegisterPopup(false);
  };

  const handleSignupClick = () => {
    navigate('/signup');
    setShowRegisterPopup(false);
  };

  return (
    <div
      className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-2 md:p-4 h-16 md:h-14 fixed bottom-4 ${leftPosition} right-4 md:right-16 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {showRegisterPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
            <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
            <div className="flex space-x-4 justify-center">
              <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleLoginClick}>Login</button>
              <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleSignupClick}>Signup</button>
            </div>
            <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>
              Close
            </button>
          </div>
        </div>
      )}
      <div className="flex items-center flex-shrink-0">
        <img src={currentRunningProgram.showPic} alt="Show" className="hidden md:block w-10 h-10 object-cover rounded-lg mr-4" />
        <div className="flex flex-col md:flex-row md:items-center md:justify-center md:space-x-4">
          <div className="flex flex-col md:items-start">
            <h3 className="text-sm font-semibold">
              <span className="md:hidden">
                {truncateTitle(currentRunningProgram.title, 12)}
              </span>
              <span className="hidden md:inline">
                {truncateTitle(currentRunningProgram.title, 25)}
              </span>
            </h3>
            <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
          </div>
          <img
            src={archiveUrl ? goLiveImage : onAirImage}
            alt={archiveUrl ? "Go Live" : "On Air"}
            className={`hidden md:block ${archiveUrl ? 'w-16 h-8' : 'w-auto h-16'} ml-6 md:ml-2 cursor-pointer`}
            style={{ cursor: 'pointer' }} // Add this style to ensure the cursor changes to pointer
            onClick={handleGoLive} // Ensure handleGoLive is assigned correctly
          />
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center space-x-4">
        <div
          className="relative flex items-center"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <FaPhone className={`transition-transform duration-300 ${!isHovered ? 'transform translate-x-8' : '-translate-x-20'}`} />
          <span className={`transition-opacity duration-300 ml-2 text-gray-800 dark:text-gray-200 font-bold transform -translate-x-20 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
            972-636-1966
          </span>
        </div>
        <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl transform -translate-x-20">
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 transform -translate-x-20">
          <FaComments />
        </button>
      </div>
      <div className="flex items-center flex-shrink-0">
        <div className="hidden md:flex items-center space-x-2">
          {archiveUrl ? (
            <div className="flex items-center space-x-2">
              <input
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                onChange={(e) => handleTimeChange(e.target.value)} // Use handleTimeChange from context
                className="ml-4"
              />
              <span className="text-xs font-bold text-gray-600 dark:text-gray-400">{`${formatTime(currentTime)}/${formatTime(duration)}`}</span>
            </div>
          ) : (
            upcomingProgram && (
              <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
                <span className="text-xs font-bold text-gray-600 dark:text-gray-400 ml-4">Upcoming: {truncateTitle(upcomingProgram.title, 25)}</span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default StreamingBar;