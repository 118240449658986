import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigationBar from '../components/NavigationBar';
import banner from '../assets/cover 007.png';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import "../styles/global.css";
import "../styles/carousel.css"; // Add a new CSS file for carousel styles
import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component

const ContactUs = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
  }, [dispatch]);

  if (!userData) {
    return <p>Loading...</p>;
  }

  return (
    <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
      <div className="fixed h-screen z-50">
        <NavigationBar setIsCollapsed={setIsCollapsed} />
      </div>
      <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
        {/* Banner for web version */}
        <div className="hidden md:block relative h-96">
          <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
        </div>

        <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <div className="flex justify-center">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 w-full md:w-1/2">
              <h2 className="text-3xl font-extrabold text-center text-gray-900 dark:text-gray-100 mb-8 tracking-[.10em]">
                Contact <span className="text-blue-600">Us</span>
              </h2>
              <div className="text-center mb-4">
                <FaPhone className="inline-block text-blue-600 mr-2" />
                <span className="text-lg text-gray-600 dark:text-gray-400">+1 972-636-1966</span>
              </div>
              <div className="text-center mb-4">
                <FaEnvelope className="inline-block text-blue-600 mr-2" />
                <span className="text-lg text-gray-600 dark:text-gray-400">admin@radiosurabhi.com</span>
              </div>
              <div className="text-center">
                <FaEnvelope className="inline-block text-blue-600 mr-2" />
                <span className="text-lg text-gray-600 dark:text-gray-400">info@radiosurabhi.com</span>
              </div>
            </div>
          </div>
        </div>

        {/* Add the StreamingBar component here */}
        <StreamingBar isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export default ContactUs;
