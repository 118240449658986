// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { signIn, signOut, getCurrentUser, fetchAuthSession } from '@aws-amplify/auth';
// import { loginStart, loginSuccess, loginFailure } from '../redux/reducers/userSlice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';
// import { faHome } from '@fortawesome/free-solid-svg-icons';
// import { Amplify } from 'aws-amplify';

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const dispatch = useDispatch();
//   const isLoading = useSelector((state) => state.user.isLoading);
//   const errorMessage = useSelector((state) => state.user.errorMessage);

//   const handleSubmit = async (event) => {
//     const username = email;
//     event.preventDefault();
//     dispatch(loginStart());
//     await signOut();
//     try {
//       const amplifyResponse = await signIn({ username, password });
//       const activeSeccion = await fetchAuthSession();
//       const accessToken = activeSeccion.tokens.accessToken.toString();
//       const identityToken = activeSeccion.tokens.idToken.toString()

//       const response = await fetch('https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();
//       if (response.ok && data.payload) {
//         let id = data.payload.data.PK.split("#")[1];
//         const userData = { ...data.payload.data, accessToken, identityToken, id };
//         dispatch(loginSuccess(userData));
//       } else {
//         dispatch(loginFailure('Failed to fetch user data'));
//       }
//     } catch (error) {
//       dispatch(loginFailure(error.message));
//     }
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-50">
//       <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg space-y-6">
//         <div className="flex justify-between items-center">
//           <h2 className="text-3xl font-extrabold text-gray-900">
//             Login to <span className="text-blue-600">Radio Surabhi</span>
//           </h2>
//           <FontAwesomeIcon icon={faHome} className="text-gray-500" />
//         </div>
//         <p className="text-center text-sm text-gray-600">
//           Welcome back! Login with your data that you entered during registration
//         </p>
//         <button className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-600 bg-white hover:bg-gray-50 flex items-center justify-center space-x-2">
//           <FontAwesomeIcon icon={faGoogle} className="text-gray-600" />
//           <span>Login with Google</span>
//         </button>
//         <div className="flex items-center justify-between space-x-3">
//           <span className="w-full border-t border-gray-300"></span>
//           <span className="text-gray-600">OR</span>
//           <span className="w-full border-t border-gray-300"></span>
//         </div>
//         <form className="space-y-6" onSubmit={handleSubmit}>
//           <div className="rounded-md shadow-sm -space-y-px">
//             <div>
//               <label htmlFor="email-address" className="sr-only">
//                 Username
//               </label>
//               <input
//                 id="email-address"
//                 name="email"
//                 type="email"
//                 autoComplete="email"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Username"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </div>
//             <div>
//               <label htmlFor="password" className="sr-only">
//                 Password
//               </label>
//               <input
//                 id="password"
//                 name="password"
//                 type="password"
//                 autoComplete="current-password"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//           </div>
//           <div className="flex items-center justify-between">
//             <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
//               Forgot Password?
//             </a>
//           </div>
//           <div>
//             <button
//               type="submit"
//               disabled={isLoading}
//               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >
//               {isLoading ? 'Logging in...' : 'Login'}
//             </button>
//           </div>
//           {errorMessage && <p className="mt-2 text-center text-sm text-red-600">{errorMessage}</p>}
//         </form>
//         <div className="text-center text-sm text-gray-600">
//           Not registered yet?{' '}
//           <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
//             Register
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;

// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { signIn, signOut, fetchAuthSession, getCurrentUser, signInWithRedirect, confirmSignIn } from '@aws-amplify/auth';
// import { loginStart, loginSuccess, loginFailure } from '../redux/reducers/userSlice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';
// import { faHome } from '@fortawesome/free-solid-svg-icons';
// import { Link, useNavigate } from 'react-router-dom';

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const dispatch = useDispatch();
//   const isLoading = useSelector((state) => state.user.isLoading);
//   const errorMessage = useSelector((state) => state.user.errorMessage);
//   const navigate = useNavigate();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     dispatch(loginStart());
//     await signOut();
//     try {
//       const amplifyResponse = await signIn({ username: email, password });
//       const activeSession = await fetchAuthSession();
//       const accessToken = activeSession.tokens.accessToken.toString();
//       const identityToken = activeSession.tokens.idToken.toString();

//       const response = await fetch('https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();
//       if (response.ok && data.payload) {
//         let id = data.payload.data.PK.split("#")[1];
//         const userData = { ...data.payload.data, accessToken, identityToken, id };
//         dispatch(loginSuccess(userData));
//         navigate('/');
//       } else {
//         dispatch(loginFailure('Failed to fetch user data'));
//       }
//     } catch (error) {
//       dispatch(loginFailure(error.message));
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     await signOut();
//     dispatch(loginStart());
//     try {
//       await signInWithRedirect({ provider: 'Google' });
//       const result = await confirmSignIn();
//       if (result) {
//         const session = await fetchAuthSession();
//         const tokenData = session.tokens.idToken.payload;
//         const email = tokenData.email;
//         const userExists = await checkUserExists(email);

//         if (!userExists) {
//           await signUp({
//             firstName: tokenData['custom:firstName'] || 'New',
//             lastName: tokenData['custom:lastName'] || 'User',
//             email,
//             password: "RS-user@123",
//             phoneNumber: tokenData['phoneNumber'] || '',
//             country: tokenData['country'] || 'US',
//             uuid: tokenData['custom:uuid'],
//             profilePic: tokenData['profile'] || '',
//           });
//         }

//         await fetchUserData(email);
//         navigate('/');
//       }
//     } catch (e) {
//       console.log(e.message)
//       dispatch(loginFailure(`Failed to sign in with Google: ${e.message}`));
//     }
//   };

//   const checkUserExists = async (email) => {
//     const url = 'https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists';
//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email }),
//       });

//       if (response.ok) {
//         const jsonData = await response.json();
//         return jsonData.userExists;
//       } else {
//         console.log(`Failed to check if user exists: ${response.status}`);
//         return false;
//       }
//     } catch (e) {
//       console.log(`Error checking if user exists: ${e}`);
//       return false;
//     }
//   };

//   const signUp = async ({ firstName, lastName, email, password, phoneNumber, uuid, country, profilePic }) => {
//     const url = 'https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/signupSSO';
//     const body = JSON.stringify({
//       firstName,
//       lastName,
//       email,
//       password,
//       phoneNumber,
//       country,
//       uuid,
//       profilePic,
//     });

//     try {
//       const response = await fetch(url, { method: 'POST', body });
//       if (!response.ok) throw new Error(`Signup failed: ${response.statusText}`);
//       await signIn({ username: email, password });
//     } catch (e) {
//       console.log(`Error during signup: ${e}`);
//       throw e;
//     }
//   };

//   const fetchUserData = async (email) => {
//     const url = 'https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists';
//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email }),
//       });

//       if (response.ok) {
//         const jsonData = await response.json();
//         if (jsonData.payload) {
//           const userData = jsonData.payload.data;
//           const id = jsonData.payload.data.PK.split("#")[1];
//           dispatch(loginSuccess({ ...userData, id }));
//         } else {
//           console.log('Payload missing or not in expected format');
//         }
//       } else {
//         console.log(`Failed to fetch user data: ${response.status}`);
//       }
//     } catch (e) {
//       console.log(`Error fetching user data: ${e}`);
//     }
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-50">
//       <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg space-y-6">
//         <div className="flex justify-between items-center">
//           <h2 className="text-3xl font-extrabold text-gray-900">
//             Login to <span className="text-blue-600">Radio Surabhi</span>
//           </h2>
//           <FontAwesomeIcon icon={faHome} className="text-gray-500" />
//         </div>
//         <p className="text-center text-sm text-gray-600">
//           Welcome back! Login with your data that you entered during registration
//         </p>
//         <button
//           className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-600 bg-white hover:bg-gray-50 flex items-center justify-center space-x-2"
//           onClick={handleGoogleSignIn}
//         >
//           <FontAwesomeIcon icon={faGoogle} className="text-gray-600" />
//           <span>Login with Google</span>
//         </button>
//         <div className="flex items-center justify-between space-x-3">
//           <span className="w-full border-t border-gray-300"></span>
//           <span className="text-gray-600">OR</span>
//           <span className="w-full border-t border-gray-300"></span>
//         </div>
//         <form className="space-y-6" onSubmit={handleSubmit}>
//           <div className="rounded-md shadow-sm -space-y-px">
//             <div>
//               <label htmlFor="email-address" className="sr-only">
//                 Username
//               </label>
//               <input
//                 id="email-address"
//                 name="email"
//                 type="email"
//                 autoComplete="email"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Username"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </div>
//             <div>
//               <label htmlFor="password" className="sr-only">
//                 Password
//               </label>
//               <input
//                 id="password"
//                 name="password"
//                 type="password"
//                 autoComplete="current-password"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//           </div>
//           <div className="flex items-center justify-between">
//             <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
//               Forgot Password?
//             </a>
//           </div>
//           <div>
//             <button
//               type="submit"
//               disabled={isLoading}
//               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >
//               {isLoading ? 'Logging in...' : 'Login'}
//             </button>
//           </div>
//           {errorMessage && <p className="mt-2 text-center text-sm text-red-600">{errorMessage}</p>}
//         </form>
//         <div className="text-center text-sm text-gray-600">
//           Not registered yet?{' '}
//           <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
//             Register
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// // export default Login_5;
// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { signIn, signOut, fetchAuthSession, signInWithRedirect, Auth } from '@aws-amplify/auth';
// import { loginStart, loginSuccess, loginFailure } from '../redux/reducers/userSlice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';
// import { faHome } from '@fortawesome/free-solid-svg-icons';
// import { Link, useNavigate } from 'react-router-dom';
// import {
//   signInWithGoogle,
//   fetchGoogleSignInSession,
//   signUpUser,
//   confirmUserSignUp,
//   autoLoginUser,
//   updateUserInDatabase,
// } from '../redux/actions/authActions';

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const dispatch = useDispatch();
//   const isLoading = useSelector((state) => state.user.isLoading);
//   const errorMessage = useSelector((state) => state.user.errorMessage);
//   const navigate = useNavigate();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     dispatch(loginStart());
//     await signOut();
//     try {
//       const amplifyResponse = await signIn({ username: email, password });
//       const activeSession = await fetchAuthSession();
//       const accessToken = activeSession.tokens.accessToken.toString();
//       const identityToken = activeSession.tokens.idToken.toString();

//       const response = await fetch('https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();
//       if (response.ok && data.payload) {
//         let id = data.payload.data.PK.split("#")[1];
//         const userData = { ...data.payload.data, accessToken, identityToken, id };
//         dispatch(loginSuccess(userData));
//         navigate('/');
//       } else {
//         dispatch(loginFailure('Failed to fetch user data'));
//       }
//     } catch (error) {
//       dispatch(loginFailure(error.message));
//     }
//   };


//   // const handleGoogleSignIn = async () => {
//   //   signOut();
//   //   await dispatch(loginStart());
//   //   try {
//   //     const result = await signInWithRedirect({ provider: 'Google' });
//   //     console.log(result);
//   //     const currentSession = await fetchAuthSession();
//   //     console.log("1")
//   //     const tokenData = currentSession.tokens.idToken.payload;
//   //     console.log("2")
//   //     const email = tokenData.email;
//   //     console.log("3")
//   //     const userExists = await checkUserExists(email);

//   //     if (!userExists) {
//   //       await signUp({
//   //         firstName: tokenData['custom:firstName'] || 'New',
//   //         lastName: tokenData['custom:lastName'] || 'User',
//   //         email,
//   //         password: "RS-user@123",
//   //         phoneNumber: tokenData['phoneNumber'] || '',
//   //         country: tokenData['country'] || 'US',
//   //         uuid: tokenData['custom:uuid'],
//   //         profilePic: tokenData['profile'] || '',
//   //       });
//   //     }

//   //     await fetchUserData(email);
//   //     // navigate('/');
//   //   } catch (e) {
//   //     console.log(e.message);
//   //     dispatch(loginFailure(`Failed to sign in with Google: ${e.message}`));
//   //   }
//   // };

//   const handleGoogleSignIn = async () => {
//     await dispatch(signInWithGoogle());
//     const result = await dispatch(fetchGoogleSignInSession());
//     if (result) {
//       const email = result.email;
//       const userExists = await checkUserExists(email);

//       if (!userExists) {
//         await dispatch(signUpUser({
//           firstName: result['custom:firstName'] || 'New',
//           lastName: result['custom:lastName'] || 'User',
//           email,
//           password: "RS-user@123",
//           phoneNumber: result['phoneNumber'] || '',
//           country: result['country'] || 'US',
//           uuid: result['custom:uuid'],
//           profilePic: result['profile'] || '',
//         }));
//       }

//       await dispatch(autoLoginUser({ email, password: "RS-user@123" }));
//       navigate('/');
//     }
//   };

//   const checkUserExists = async (email) => {
//     const url = 'https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists';
//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email }),
//       });

//       if (response.ok) {
//         const jsonData = await response.json();
//         return jsonData.userExists;
//       } else {
//         console.log(`Failed to check if user exists: ${response.status}`);
//         return false;
//       }
//     } catch (e) {
//       console.log(`Error checking if user exists: ${e}`);
//       return false;
//     }
//   };

//   const signUp = async ({ firstName, lastName, email, password, phoneNumber, uuid, country, profilePic }) => {
//     const url = 'https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/signupSSO';
//     const body = JSON.stringify({
//       firstName,
//       lastName,
//       email,
//       password,
//       phoneNumber,
//       country,
//       uuid,
//       profilePic,
//     });

//     try {
//       const response = await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body });
//       if (!response.ok) throw new Error(`Signup failed: ${response.statusText}`);
//       await signIn({ username: email, password });
//     } catch (e) {
//       console.log(`Error during signup: ${e}`);
//       throw e;
//     }
//   };

//   const fetchUserData = async (email) => {
//     const url = 'https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists';
//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email }),
//       });

//       if (response.ok) {
//         const jsonData = await response.json();
//         if (jsonData.payload) {
//           const userData = jsonData.payload.data;
//           const id = jsonData.payload.data.PK.split("#")[1];
//           dispatch(loginSuccess({ ...userData, id }));
//         } else {
//           console.log('Payload missing or not in expected format');
//         }
//       } else {
//         console.log(`Failed to fetch user data: ${response.status}`);
//       }
//     } catch (e) {
//       console.log(`Error fetching user data: ${e}`);
//     }
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-50">
//       <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg space-y-6">
//         <div className="flex justify-between items-center">
//           <h2 className="text-3xl font-extrabold text-gray-900">
//             Login to <span className="text-blue-600">Radio Surabhi</span>
//           </h2>
//           <FontAwesomeIcon icon={faHome} className="text-gray-500" />
//         </div>
//         <p className="text-center text-sm text-gray-600">
//           Welcome back! Login with your data that you entered during registration
//         </p>
//         <button
//           className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-600 bg-white hover:bg-gray-50 flex items-center justify-center space-x-2"
//           onClick={handleGoogleSignIn}
//         >
//           <FontAwesomeIcon icon={faGoogle} className="text-gray-600" />
//           <span>Login with Google</span>
//         </button>
//         <div className="flex items-center justify-between space-x-3">
//           <span className="w-full border-t border-gray-300"></span>
//           <span className="text-gray-600">OR</span>
//           <span className="w-full border-t border-gray-300"></span>
//         </div>
//         <form className="space-y-6" onSubmit={handleSubmit}>
//           <div className="rounded-md shadow-sm -space-y-px">
//             <div>
//               <label htmlFor="email-address" className="sr-only">
//                 Username
//               </label>
//               <input
//                 id="email-address"
//                 name="email"
//                 type="email"
//                 autoComplete="email"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Username"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </div>
//             <div>
//               <label htmlFor="password" className="sr-only">
//                 Password
//               </label>
//               <input
//                 id="password"
//                 name="password"
//                 type="password"
//                 autoComplete="current-password"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//           </div>
//           <div className="flex items-center justify-between">
//             <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
//               Forgot Password?
//             </a>
//           </div>
//           <div>
//             <button
//               type="submit"
//               disabled={isLoading}
//               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >
//               {isLoading ? 'Logging in...' : 'Login'}
//             </button>
//           </div>
//           {errorMessage && <p className="mt-2 text-center text-sm text-red-600">{errorMessage}</p>}
//         </form>
//         <div className="text-center text-sm text-gray-600">
//           Not registered yet?{' '}
//           <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
//             Register
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { signIn, signOut, fetchAuthSession, signInWithRedirect } from '@aws-amplify/auth';
// import { loginStart, loginSuccess, loginFailure } from '../redux/reducers/userSlice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';
// import { faHome } from '@fortawesome/free-solid-svg-icons';
// import { Link, useNavigate } from 'react-router-dom';
// import {
//   signInWithGoogle,
//   fetchGoogleSignInSession,
//   signUpUser,
//   autoLoginUser,
// } from '../redux/actions/authActions';
// import backgroundImage from '../assets/web-login-bg.jpg';
// import logo from '../assets/logo.png'; // Import your logo image here

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const dispatch = useDispatch();
//   const isLoading = useSelector((state) => state.user.isLoading);
//   const errorMessage = useSelector((state) => state.user.errorMessage);
//   const navigate = useNavigate();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     dispatch(loginStart());
//     await signOut();
//     try {
//       const amplifyResponse = await signIn({ username: email, password });
//       const activeSession = await fetchAuthSession();
//       const accessToken = activeSession.tokens.accessToken.toString();
//       const identityToken = activeSession.tokens.idToken.toString();

//       const response = await fetch('https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();
//       if (response.ok && data.payload) {
//         let id = data.payload.data.PK.split("#")[1];
//         const userData = { ...data.payload.data, accessToken, identityToken, id };
//         dispatch(loginSuccess(userData));
//         navigate('/');
//       } else {
//         dispatch(loginFailure('Failed to fetch user data'));
//       }
//     } catch (error) {
//       dispatch(loginFailure(error.message));
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     await dispatch(signInWithGoogle());
//     const result = await dispatch(fetchGoogleSignInSession());
//     if (result) {
//       const email = result.email;
//       const userExists = await checkUserExists(email);

//       if (!userExists) {
//         await dispatch(signUpUser({
//           firstName: result['custom:firstName'] || 'New',
//           lastName: result['custom:lastName'] || 'User',
//           email,
//           password: "RS-user@123",
//           phoneNumber: result['phoneNumber'] || '',
//           country: result['country'] || 'US',
//           uuid: result['custom:uuid'],
//           profilePic: result['profile'] || '',
//         }));
//       }

//       await dispatch(autoLoginUser({ email, password: "RS-user@123" }));
//       navigate('/');
//     }
//   };

//   const checkUserExists = async (email) => {
//     const url = 'https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists';
//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email }),
//       });

//       if (response.ok) {
//         const jsonData = await response.json();
//         return jsonData.userExists;
//       } else {
//         console.log(`Failed to check if user exists: ${response.status}`);
//         return false;
//       }
//     } catch (e) {
//       console.log(`Error checking if user exists: ${e}`);
//       return false;
//     }
//   };

//   const joinAsGuest = () => {
//     navigate('/home', { state: { isGuest: true } });
//   };

//   return (
//     <div
//       className="min-h-screen flex flex-col items-center justify-center bg-gray-50"
//       style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
//     >
//       <img src={logo} alt="Logo" className="mb-8 w-40 h-auto" /> {/* Adjust the size as needed */}
//       <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg space-y-6">
//         <div className="flex justify-between items-center">
//           <h2 className="text-3xl font-extrabold text-gray-900">
//             Login to <span className="text-blue-600">Radio Surabhi</span>
//           </h2>
//           <FontAwesomeIcon icon={faHome} className="text-gray-500" />
//         </div>
//         <p className="text-center text-sm text-gray-600">
//           Welcome back! Login with your data that you entered during registration
//         </p>
//         <button
//           className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-600 bg-white hover:bg-gray-50 flex items-center justify-center space-x-2"
//           onClick={handleGoogleSignIn}
//         >
//           <FontAwesomeIcon icon={faGoogle} className="text-gray-600" />
//           <span>Login with Google</span>
//         </button>
//         <div className="flex items-center justify-between space-x-3">
//           <span className="w-full border-t border-gray-300"></span>
//           <span className="text-gray-600">OR</span>
//           <span className="w-full border-t border-gray-300"></span>
//         </div>
//         <form className="space-y-6" onSubmit={handleSubmit}>
//           <div className="rounded-md shadow-sm -space-y-px">
//             <div>
//               <label htmlFor="email-address" className="sr-only">
//                 Username
//               </label>
//               <input
//                 id="email-address"
//                 name="email"
//                 type="email"
//                 autoComplete="email"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Username"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </div>
//             <div>
//               <label htmlFor="password" className="sr-only">
//                 Password
//               </label>
//               <input
//                 id="password"
//                 name="password"
//                 type="password"
//                 autoComplete="current-password"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//           </div>
//           <div className="flex items-center justify-between">
//             <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
//               Forgot Password?
//             </a>
//           </div>
//           <div>
//             <button
//               type="submit"
//               disabled={isLoading}
//               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >
//               {isLoading ? 'Logging in...' : 'Login'}
//             </button>
//           </div>
//           {errorMessage && <p className="mt-2 text-center text-sm text-red-600">{errorMessage}</p>}
//         </form>
//         <div className="text-center text-sm text-gray-600">
//           Not registered yet?{' '}
//           <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
//             Register
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Hub } from '@aws-amplify/core';
// import { signOut, signIn, fetchAuthSession, signInWithRedirect, getCurrentUser } from '@aws-amplify/auth';
// import { loginStart, loginSuccess, loginFailure } from '../redux/reducers/userSlice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';
// import { faHome } from '@fortawesome/free-solid-svg-icons';
// import { Link, useNavigate } from 'react-router-dom';
// import { signUpUser, autoLoginUser, checkUserExists, updateUserInDatabase } from '../redux/actions/authActions';
// import backgroundImage from '../assets/web-login-bg.jpg';
// import logo from '../assets/logo.png';

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const dispatch = useDispatch();
//   const isLoading = useSelector((state) => state.user.isLoading);
//   const errorMessage = useSelector((state) => state.user.errorMessage);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = Hub.listen('auth', async ({ payload }) => {
//       switch (payload.event) {
//         case 'signedIn':
//           await handleSignIn();
//           break;
//         case 'signIn_failure':
//           dispatch(loginFailure('An error occurred during the OAuth flow.'));
//           break;
//         case 'customOAuthState':
//           break;
//         default:
//           break;
//       }
//     });

//     getUser();

//     return () => unsubscribe();
//   }, []);

//   const getUser = async () => {
//     try {
//       const currentUser = await getCurrentUser();
//     } catch (error) {
//       console.error('Error getting current user:', error);
//     }
//   };

//   const handleSignIn = async () => {
//     try {
//       const session = await fetchAuthSession();

//       const idToken = session.tokens.idToken.toString();
//       const tokenData = JSON.parse(atob(idToken.split('.')[1]));
//       const email = tokenData.email;
//       const accessToken= session.tokens.accessToken.toString();
//       const identityToken = session.tokens.idToken.toString;
//       const userExists = await dispatch(checkUserExists(email));


//       if (!userExists) {
       
//         const userData = {
//           firstName: tokenData['custom:firstName'] || 'New',
//           lastName: tokenData['custom:lastName'] || 'User',
//           email,
//           password: "RS-user@123",
//           phoneNumber: tokenData['phoneNumber'] || '',
//           country: tokenData['country'] || 'US',
//           uuid: tokenData['custom:uuid'],
//           profilePic: tokenData['profile'] || '',
//           dob: tokenData['dob'] || '',
//         };

//         const signupResult = await dispatch(signUpUser({ email, password: "RS-user@123", userData }));
        
//         if (signupResult.success) {
          
//           await updateUserInDatabase(userData);
//         }
//       }

      
//        const response = await fetch('https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();
//       if (response.ok && data.payload) {
//         let id = data.payload.data.PK.split("#")[1];
//         const userData = { ...data.payload.data, accessToken, identityToken, id };
//         dispatch(loginSuccess(userData));
//         navigate('/');
//       } else {
//         dispatch(loginFailure('Failed to fetch user data'));
//       }
//     } catch (error) {
//       console.error('Error during handleSignIn:', error);
//       dispatch(loginFailure(error.message));
//     }
//   };

//   const fetchUserByEmail = async (email) => {
//     try {
//       const response = await fetch('https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/get-user-by-email', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();
//       if (response.ok && data.payload) {
//         const userData = data.payload.data;
//         console.log('User data:', userData);
//         dispatch(loginSuccess(userData));
//         navigate('/');
//       } else {
//         throw new Error('Failed to fetch user data');
//       }
//     } catch (error) {
//       console.error('Error fetching user by email:', error);
//       dispatch(loginFailure(error.message));
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     dispatch(loginStart());
//     await signOut();
//     try {
//       const amplifyResponse = await signIn({ username: email, password });
//       const activeSession = await fetchAuthSession();
//       const accessToken = activeSession.tokens.accessToken.toString();
//       const identityToken = activeSession.tokens.idToken.toString();

//       const response = await fetch('https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();
//       if (response.ok && data.payload) {
//         let id = data.payload.data.PK.split("#")[1];
//         const userData = { ...data.payload.data, accessToken, identityToken, id };
//         dispatch(loginSuccess(userData));
//         navigate('/');
//       } else {
//         dispatch(loginFailure('Failed to fetch user data'));
//       }
//     } catch (error) {
//       dispatch(loginFailure(error.message));
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     await signOut();
//     dispatch(loginStart());
//     try {
//       await signInWithRedirect({ provider: 'Google' });
//     } catch (error) {
//       console.error('Error during Google sign-in:', error);
//       dispatch(loginFailure(error.message));
//     }
//   };

//   const joinAsGuest = () => {
//     navigate('/home', { state: { isGuest: true } });
//   };

//   return (
//     <div
//       className="min-h-screen flex flex-col items-center justify-center bg-gray-50"
//       style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
//     >
//       <img src={logo} alt="Logo" className="mb-8 w-40 h-auto" /> {/* Adjust the size as needed */}
//       <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg space-y-6">
//         <div className="flex justify-between items-center">
//           <h2 className="text-3xl font-extrabold text-gray-900">
//             Login to <span className="text-blue-600">Radio Surabhi</span>
//           </h2>
//           <FontAwesomeIcon icon={faHome} className="text-gray-500" />
//         </div>
//         <p className="text-center text-sm text-gray-600">
//           Welcome back! Login with your data that you entered during registration
//         </p>
//         <button
//           className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-600 bg-white hover:bg-gray-50 flex items-center justify-center space-x-2"
//           onClick={handleGoogleSignIn}
//         >
//           <FontAwesomeIcon icon={faGoogle} className="text-gray-600" />
//           <span>Login with Google</span>
//         </button>
//         <div className="flex items-center justify-between space-x-3">
//           <span className="w-full border-t border-gray-300"></span>
//           <span className="text-gray-600">OR</span>
//           <span className="w-full border-t border-gray-300"></span>
//         </div>
//         <form className="space-y-6" onSubmit={handleSubmit}>
//           <div className="rounded-md shadow-sm -space-y-px">
//             <div>
//               <label htmlFor="email-address" className="sr-only">
//                 Username
//               </label>
//               <input
//                 id="email-address"
//                 name="email"
//                 type="email"
//                 autoComplete="email"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Username"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </div>
//             <div>
//               <label htmlFor="password" className="sr-only">
//                 Password
//               </label>
//               <input
//                 id="password"
//                 name="password"
//                 type="password"
//                 autoComplete="current-password"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//           </div>
//           <div className="flex items-center justify-between">
//             <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
//               Forgot Password?
//             </a>
//           </div>
//           <div>
//             <button
//               type="submit"
//               disabled={isLoading}
//               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >
//               {isLoading ? 'Logging in...' : 'Login'}
//             </button>
//           </div>
//           {errorMessage && <p className="mt-2 text-center text-sm text-red-600">{errorMessage}</p>}
//         </form>
//         <div className="text-center text-sm text-gray-600">
//           Not registered yet?{' '}
//           <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
//             Register
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Hub } from '@aws-amplify/core';
import { signOut, signIn, fetchAuthSession, signInWithRedirect, getCurrentUser } from '@aws-amplify/auth';
import { loginStart, loginSuccess, loginFailure, setGuest } from '../redux/reducers/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { signUpUser, autoLoginUser, checkUserExists, updateUserInDatabase } from '../redux/actions/authActions';
import backgroundImage from '../assets/web-login-bg.jpg';
import logo from '../assets/logo.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);
  const errorMessage = useSelector((state) => state.user.errorMessage);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          await handleSignIn();
          break;
        case 'signIn_failure':
          dispatch(loginFailure('An error occurred during the OAuth flow.'));
          break;
        case 'customOAuthState':
          break;
        default:
          break;
      }
    });

    getUser();

    return () => unsubscribe();
  }, []);

  const getUser = async () => {
    try {
      const currentUser = await getCurrentUser();
    } catch (error) {
      console.error('Error getting current user:', error);
    }
  };

  const handleSignIn = async () => {
    try {
      const session = await fetchAuthSession();

      const idToken = session.tokens.idToken.toString();
      
      const tokenData = JSON.parse(atob(idToken.split('.')[1]));
      const email = tokenData.email;
      const accessToken= session.tokens.accessToken.toString();
      const identityToken = session.tokens.idToken.toString();
      const userExists = await dispatch(checkUserExists(email));

      if (!userExists) {
        const userData = {
          firstName: tokenData['custom:firstName'] || 'New',
          lastName: tokenData['custom:lastName'] || 'User',
          email,
          password: "RS-user@123",
          phoneNumber: tokenData['phoneNumber'] || '',
          country: tokenData['country'] || 'US',
          uuid: tokenData['custom:uuid'],
          profilePic: tokenData['profile'] || '',
          dob: tokenData['dob'] || '',
        };

        const signupResult = await dispatch(signUpUser({ email, password: "RS-user@123", userData }));
        
        if (signupResult.success) {
          await updateUserInDatabase(userData);
        }
      }

      const response = await fetch('https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      console.log(response);
      if (response.ok && data.payload) {
        let id = data.payload.data.PK.split("#")[1];
        const userData = { ...data.payload.data, accessToken, identityToken, id };
        dispatch(loginSuccess(userData));
        navigate('/');
      } else {
        dispatch(loginFailure('Failed to fetch user data'));
      }
    } catch (error) {
      console.error('Error during handleSignIn:', error);
      dispatch(loginFailure(error.message));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(loginStart());
    await signOut();
    try {
      const amplifyResponse = await signIn({ username: email, password });
      const activeSession = await fetchAuthSession();
      const accessToken = activeSession.tokens.accessToken.toString();
      const identityToken = activeSession.tokens.idToken.toString();

      const response = await fetch('https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok && data.payload) {
        let id = data.payload.data.PK.split("#")[1];
        const userData = { ...data.payload.data, accessToken, identityToken, id };
        dispatch(loginSuccess(userData));
        navigate('/');
      } else {
        dispatch(loginFailure('Failed to fetch user data'));
      }
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };

  const handleGoogleSignIn = async () => {
    await signOut();
    dispatch(loginStart());
    try {
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      dispatch(loginFailure(error.message));
    }
  };

  const joinAsGuest = () => {
    dispatch(setGuest());
    navigate('/');
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center bg-gray-50"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <img src={logo} alt="Logo" className="mb-8 w-40 h-auto" /> {/* Adjust the size as needed */}
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Login to <span className="text-blue-600">Radio Surabhi</span>
          </h2>
          <FontAwesomeIcon icon={faHome} className="text-gray-500" />
        </div>
        <p className="text-center text-sm text-gray-600">
          Welcome back! Login with your data that you entered during registration
        </p>
        <button
          className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-600 bg-white hover:bg-gray-50 flex items-center justify-center space-x-2"
          onClick={handleGoogleSignIn}
        >
          <FontAwesomeIcon icon={faGoogle} className="text-gray-600" />
          <span>Login with Google</span>
        </button>
        <div className="flex items-center justify-between space-x-3">
          <span className="w-full border-t border-gray-300"></span>
          <span className="text-gray-600">OR</span>
          <span className="w-full border-t border-gray-300"></span>
        </div>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Username
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
              Forgot Password?
            </a>
          </div>
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
          {errorMessage && <p className="mt-2 text-center text-sm text-red-600">{errorMessage}</p>}
        </form>
        <button
          className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-600 bg-white hover:bg-gray-50 flex items-center justify-center space-x-2 mt-4"
          onClick={joinAsGuest}
        >
          <span>Join as Guest</span>
        </button>
        <div className="text-center text-sm text-gray-600">
          Not registered yet?{' '}
          <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
            Register
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
