// src/redux/actions/categoriesActions.js
import { fetchCategoriesStart, fetchCategoriesSuccess, fetchCategoriesFailure } from '../reducers/categoriesSlice';
import apiService from '../api-service/apiService';

export const fetchCategoryList = (forGuest) => async (dispatch, getState) => {
  dispatch(fetchCategoriesStart());

  try {
    const { userData } = getState().user;
    const categories = await apiService.fetchCategoryList(forGuest, userData?.identityToken, userData?.id);
    dispatch(fetchCategoriesSuccess(categories));
  } catch (error) {
    dispatch(fetchCategoriesFailure(error.message));
  }
};
