import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigationBar from '../components/NavigationBar';
import member1 from '../assets/member1.jpg';
import member2 from '../assets/member2.jpg';
import member3 from '../assets/member3.jpg';
import member4 from '../assets/member4.jpg';
import banner from '../assets/cover 007.png'
import visionImage from '../assets/aboui-raji1.jpg'; // Add the image for "The Vision" section
import "../styles/global.css";
import "../styles/carousel.css"; // Add a new CSS file for carousel styles
import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component

const AboutUs = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
  }, [dispatch]);

  if (!userData) {
    return <p>Loading...</p>;
  }

  return (
    <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
      <div className="fixed h-screen z-50">
        <NavigationBar setIsCollapsed={setIsCollapsed} />
      </div>
      <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
        {/* Banner for web version */}
        <div className="hidden md:block relative h-96">
          <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-gray-50 dark:to-gray-900"></div>
        </div>

        <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            About <span className="text-blue-600">Us</span>
          </h2>

          {/* Members images for mobile version */}
          <div className="md:hidden flex justify-center items-center mb-4">
            <div className="flex space-x-4">
              <img src={member1} alt="Member 1" className="rounded-rectangular-image mt-10" />
              <img src={member2} alt="Member 2" className="rounded-rectangular-image" />
              <img src={member3} alt="Member 3" className="rounded-rectangular-image mt-10" />
              <img src={member4} alt="Member 4" className="rounded-rectangular-image" />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between mb-16">
            {/* Text section for web and mobile */}
            <div className="w-full md:w-1/2 pr-8">
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">
                In the heart of Dallas, a unique echo resonates, one that heralds the dawn of America's first 24/7 Telugu radio channel, where quirky openings, reminiscent of beloved Telugu movie dialogues, dance through the airwaves. Welcome to Radio Surabhi, where the frequency 104.9 HD4 is not just a number but a portal to a world where over 100k listeners find solace and connection.
              </p>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">
                From decoding generational trends for everyone to offering daily doses of mental and physical wellness with conscious living, from delivering a concise news bulletin spanning the US and India to becoming a trusted companion during evening commutes, Radio Surabhi caters to all, ensuring the Telugu diaspora remains rooted in their cultural heritage.
              </p>
            </div>

            {/* Members images for web version */}
            <div className="hidden md:flex w-1/2 justify-center items-center">
              <div className="flex space-x-4">
                <img src={member1} alt="Member 1" className="rounded-rectangular-image mt-10" />
                <img src={member3} alt="Member 3" className="rounded-rectangular-image" />
                <img src={member4} alt="Member 4" className="rounded-rectangular-image mt-10" />
                <img src={member2} alt="Member 2" className="rounded-rectangular-image" />
              </div>
            </div>
          </div>

          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            The <span className="text-blue-600">Vision</span>
          </h2>
          <div className="flex flex-col md:flex-row justify-between pt-8">
            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img src={visionImage} alt="Vision" className='w-auto h-svh rounded-[30px]' />
            </div>
            <div className="w-full md:w-1/2 pl-4 pr-8 pt-16">
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">
                Started in 2018 by Rajeswari Udayagiri, a daring IT professional who traded her 23-year career for the realm of radio, this vibrant channel bloomed into full-time operation in 2022, backed by a team of 30+ volunteers and three-four dedicated employees. What began as a humble venture swiftly evolved into a community sensation, bridging the gap between nostalgia-laden Tollywood classics and the pulse of modern life, offering not just musical delights but also a curated guide to the latest food joints, bustling businesses, vibrant cultural events, things to do in town, celebrity interviews and much more…
              </p>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">
                Today, Radio Surabhi stands tall as a household staple, a testament to one woman's vision and passion. Rajeswari, the trailblazing founder, propelled Telugu radio onto the global stage, transforming Radio Surabhi into more than just a medium but a vibrant infotainment hub for NRIs everywhere. With a dash of social media charm and a sprinkle of inclusive cultural events, Radio Surabhi proudly upholds its tagline: "America Lo Telugu Nestham"... where Telugu finds its home away from home.
              </p>
            </div>
          </div>
        </div>

        {/* Add the StreamingBar component here */}
        <StreamingBar isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export default AboutUs;
