import { fetchMoviesStart, fetchMoviesSuccess, fetchMoviesFailure, toggleLikeSuccess, toggleLikeFailure } from '../reducers/moviesSlice';
import apiService from '../api-service/apiService';

export const fetchMoviesList = () => async (dispatch, getState) => {
  dispatch(fetchMoviesStart());

  try {
    const { userData } = getState().user;
    const movies = await apiService.fetchMoviesList(userData);
    dispatch(fetchMoviesSuccess(movies));
  } catch (error) {
    dispatch(fetchMoviesFailure(error.message));
  }
};

export const toggleLike = (movieId) => async (dispatch, getState) => {
  const { userData } = getState().user;
  const movie = getState().movies.movies.find(movie => movie.PK === movieId);
  const action = movie.likedByUser ? 'dislike' : 'like';

  try {
    await apiService.toggleLike(userData, movieId, action);
    dispatch(toggleLikeSuccess(movieId));
  } catch (error) {
    dispatch(toggleLikeFailure(error.message));
  }
};
