// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProgramsList } from '../redux/actions/programsActions';
// import { fetchNewsList } from '../redux/actions/newsActions';
// import { fetchCategoryList } from '../redux/actions/categoriesActions';
// import { fetchAds } from '../redux/actions/adsActions';
// import { fetchRjPostersList } from '../redux/actions/rjPostersActions';
// import apiService from '../redux/api-service/apiService';
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import "../styles/global.css";
// import "../styles/carousel.css";
// import { FaHeart, FaRegHeart, FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';
// import Slider from "react-slick";
// import StreamingBar from '../components/StreamingBar';
// import PopUpAd from '../components/PopUpAd';
// import NewsPopup from '../components/NewsPopup';
// import CategoryPopup from '../components/CategoryDetails';
// import { logout } from '../redux/reducers/userSlice';
// import { Link, useLocation, useNavigate } from 'react-router-dom';

// const isMobileBrowser = () => {
//   return /Mobi|Android/i.test(navigator.userAgent);
// };

// const Home = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const userData = useSelector((state) => state.user.userData);
//   const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
//   const { news, isLoading: isNewsLoading, errorMessage: newsError } = useSelector((state) => state.news);
//   const { categories, isLoading: isCategoriesLoading, errorMessage: categoriesError } = useSelector((state) => state.categories);
//   const { ads, isLoading: isAdsLoading, errorMessage: adsError } = useSelector((state) => state.ads);
//   const { rjPosters, isLoading: isRjPostersLoading, errorMessage: rjPostersError } = useSelector((state) => state.rjPosters);

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [interestedCategories, setInterestedCategories] = useState([]);
//   const [showPopUpAd, setShowPopUpAd] = useState(false);
//   const [currentPopUpAd, setCurrentPopUpAd] = useState(null);
//   const [bannerAdIndex, setBannerAdIndex] = useState(0);
//   const [selectedNews, setSelectedNews] = useState(null);
//   const [showRegisterPopup, setShowRegisterPopup] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [selectedRJ, setSelectedRJ] = useState(null);
//   const [isAutoPlay, setIsAutoPlay] = useState(true);

//   useEffect(() => {
//     dispatch(fetchProgramsList(userData.isGuest));
//     dispatch(fetchNewsList(userData.isGuest));
//     dispatch(fetchCategoryList(userData.isGuest));
//     dispatch(fetchAds(true));
//     dispatch(fetchRjPostersList(userData.isGuest)); // Fetch RJ posters
//     if (userData && userData.categories) {
//       setInterestedCategories(userData.categories);
//     }
//   }, [dispatch, userData]);

//   const bannerAds = ads.filter(ad => ad.type === '2');

//   useEffect(() => {
//     if (bannerAds.length > 0) {
//       const showRandomBannerAd = () => {
//         const randomIndex = Math.floor(Math.random() * bannerAds.length);
//         setBannerAdIndex(randomIndex);
//       };

//       showRandomBannerAd();

//       const interval = setInterval(() => {
//         showRandomBannerAd();
//       }, 18000);

//       return () => clearInterval(interval);
//     }
//   }, [bannerAds]);

//   const currentBannerAd = bannerAds[bannerAdIndex];

//   const toggleInterest = async (categoryId) => {
//     try {
//       const accessToken = userData.identityToken;
//       const userId = userData.id;

//       const response = await apiService.toggleFavorite(userId, accessToken, categoryId);

//       if (response) {
//         setInterestedCategories((prevState) =>
//           prevState.includes(categoryId)
//             ? prevState.filter((id) => id !== categoryId)
//             : [...prevState, categoryId]
//         );
//       } else {
//         throw new Error('Failed to favorite the category');
//       }
//     } catch (error) {
//       console.error('Error favoriting category:', error);
//     }
//   };

//   const handleNewsClick = (newsItem) => {
//     setSelectedNews(newsItem);
//   };

//   const closeNewsPopup = () => {
//     setSelectedNews(null);
//   };

//   const handleRestrictedNavigation = (e) => {
//     if (userData.isGuest) {
//       e.preventDefault();
//       setShowRegisterPopup(true);
//     }
//   };

//   const closeRegisterPopup = () => {
//     setShowRegisterPopup(false);
//   };

//   const handleCategoryClick = (categoryId) => {
//     if (userData.isGuest) {
//       setShowRegisterPopup(true);
//     } else {
//       setSelectedCategory(categoryId);
//     }
//   };

//   const closeCategoryPopup = () => {
//     setSelectedCategory(null);
//   };

//   const handleRJClick = (poster) => {
//     if (isMobileBrowser()) {
//       setSelectedRJ(poster);
//       setIsAutoPlay(false);
//     }
//   };

//   const closeRJPopup = (e) => {
//     if (e.target.id === 'rj-popup-container' || e.target.tagName === 'BUTTON') {
//       setSelectedRJ(null);
//       setIsAutoPlay(true);
//     }
//   };

//   if (!userData) {
//     return <p>Loading...</p>;
//   }

//   const carouselSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//     centerMode: true,
//     centerPadding: "0",
//     prevArrow: <CustomPrevArrow />,
//     nextArrow: <CustomNextArrow />
//   };

//   function CustomPrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <FaArrowLeft
//         className={`${className} custom-arrow`}
//         style={{ ...style, display: "block", position: "absolute", left: "10px", zIndex: 1 }}
//         onClick={onClick}
//       />
//     );
//   }

//   function CustomNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <FaArrowRight
//         className={`${className} custom-arrow`}
//         style={{ ...style, display: "block", position: "absolute", right: "10px", zIndex: 1 }}
//         onClick={onClick}
//       />
//     );
//   }

//   const handleLoginClick = () => {
//     dispatch(logout());
//     closeRegisterPopup();
//     navigate('/login');
//   };

//   const handleSignupClick = () => {
//     dispatch(logout());
//     closeRegisterPopup();
//     navigate('/signup');
//   };

//   return (
//     <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
//       {selectedNews && (
//         <NewsPopup news={selectedNews} onClose={closeNewsPopup} />
//       )}
//       {showRegisterPopup && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
//             <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
//             <div className="flex space-x-4 justify-center">
//               <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleLoginClick}>Login</button>
//               <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleSignupClick}>Signup</button>
//             </div>
//             <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>Close</button>
//           </div>
//         </div>
//       )}
//       {selectedCategory && (
//         <CategoryPopup
//           userData={userData}
//           categoryId={selectedCategory}
//           onClose={closeCategoryPopup}
//         />
//       )}
//       {selectedRJ && isMobileBrowser() && (
//         <div id="rj-popup-container" className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeRJPopup}>
//           <div className="relative bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
//             <button className="absolute top-2 right-2 text-gray-900 dark:text-gray-100" onClick={closeRJPopup}>
//               <FaTimes />
//             </button>
//             <img src={selectedRJ.thumbnail} alt={selectedRJ.rjName} className="w-full h-auto object-cover rounded-lg" />
//           </div>
//         </div>
//       )}
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
//         <div className="relative h-96">
//           <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
//           <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
//         </div>
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Top <span className="text-blue-600">Shows</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">You shouldn't miss today</p>
//           {isProgramsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
//           ) : programsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {programs.map((program) => (
//                     <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
//                       <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* News and Categories Section */}
//         <div className="px-4 md:px-16 pt-0 md:pt-8 bg-gray-50 dark:bg-gray-900 flex flex-col md:flex-row space-y-16 md:space-y-0 md:space-x-16">
//           {/* Latest News Section */}
//           <div className="w-full md:w-1/2 pr-2">
//             <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//               Latest <span className="text-blue-600">News</span>
//             </h2>
//             <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Stay updated with the latest news</p>
//             {isNewsLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading news...</p>
//             ) : newsError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading news: {newsError}</p>
//             ) : (
//               <div className="relative">
//                 <div className="no-scrollbar overflow-x-scroll">
//                   <div className="flex space-x-6">
//                     {news.map((item) => (
//                       <div key={item.SK} className="min-w-[220px] max-w-[200px]" onClick={() => handleNewsClick(item)}>
//                         <img src={item.thumbnail} alt={item.title} className="w-full h-auto object-cover rounded-lg" />
//                         <div className="pt-2">
//                           <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{item.title}</h3>
//                           <p className="text-gray-600 dark:text-gray-400">{new Date(item.updatedAt).toLocaleDateString()}</p>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
          
//           {/* Categories Section */}
//           <div className="w-full md:w-1/2 pl-2 hidden md:block">
//             <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//               Categories
//             </p>
//             {isCategoriesLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//             ) : categoriesError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//             ) : (
//               <div className="relative h-72 overflow-y-scroll no-scrollbar">
//                 <div className="flex flex-col space-y-6">
//                   {categories.map((category) => (
//                     <div key={category.PK} className="flex items-center space-x-4" onClick={() => handleCategoryClick(category.PK)}>
//                       <img src={category.thumbnail} alt={category.title} className="w-16 h-16 object-cover rounded-lg" />
//                       <div className="ml-4 flex-1">
//                         <h3 className="text-l font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{category.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">Shows: {category.programCount}</p>
//                       </div>
//                       <button onClick={(e) => {e.stopPropagation(); toggleInterest(category.PK);}} className="ml-4">
//                         {interestedCategories.includes(category.PK) ? (
//                           <FaHeart className="text-red-500" />
//                         ) : (
//                           <FaRegHeart className="text-gray-400" />
//                         )}
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Banner Ads Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           {isAdsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
//           ) : adsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
//           ) : (
//             <div className="relative">
//               {currentBannerAd && (
//                 <div className="flex justify-center items-center">
//                   <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
//                     <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-auto h-auto object-cover rounded-lg" />
//                   </a>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>

//         {/* Categories Section for Mobile */}
//         <div className="px-4 md:px-16 pt-4 md:hidden bg-gray-50 dark:bg-gray-900">
//           <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//             Categories
//           </p>
//           {isCategoriesLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//           ) : categoriesError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//           ) : (
//             <div className="relative no-scrollbar overflow-x-scroll">
//               <div className="flex space-x-6">
//                 {categories.map((category) => (
//                   <div key={category.PK} className="flex-shrink-0" onClick={() => handleCategoryClick(category.PK)}>
//                     <img src={category.thumbnail} alt={category.title} className="w-40 h-40 object-cover rounded-lg" />
//                     <p className="text-center text-gray-900 dark:text-gray-100 mt-2">{category.title}</p>
//                     <button onClick={(e) => {e.stopPropagation(); toggleInterest(category.PK);}} className="block mx-auto mt-2">
//                       {interestedCategories.includes(category.PK) ? (
//                         <FaHeart className="text-red-500" />
//                       ) : (
//                         <FaRegHeart className="text-gray-400" />
//                       )}
//                     </button>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Our RJs Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Our <span className="text-blue-600">RJs</span>
//           </h2>
//           <div className="relative carousel-wrapper">
//             {isRjPostersLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading RJ posters...</p>
//             ) : rjPostersError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading RJ posters: {rjPostersError}</p>
//             ) : (
//               <Slider {...carouselSettings}>
//                 {rjPosters.map((poster, index) => (
//                   <div key={index} className="p-4 md:p-14">
//                     <div className="carousel-item">
//                       <img 
//                         src={poster.thumbnail} // Assuming the poster object has a 'thumbnail' property
//                         alt={poster.rjName} // Assuming the poster object has a 'rjName' property
//                         className="w-full h-full object-cover rounded-lg" 
//                         onClick={() => handleRJClick(poster)} // Add onClick event for mobile
//                       />
//                     </div>
//                   </div>
//                 ))}
//               </Slider>
//             )}
//           </div>
//         </div>
        
//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// export default Home;

// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProgramsList } from '../redux/actions/programsActions';
// import { fetchNewsList } from '../redux/actions/newsActions';
// import { fetchCategoryList } from '../redux/actions/categoriesActions';
// import { fetchAds } from '../redux/actions/adsActions';
// import { fetchRjPostersList } from '../redux/actions/rjPostersActions';
// import { setStreamingURLs, setSelectedCity } from '../redux/reducers/streamingBarSlice';
// import apiService from '../redux/api-service/apiService';
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import "../styles/global.css";
// import "../styles/carousel.css";
// import { FaHeart, FaRegHeart, FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';
// import Slider from "react-slick";
// import StreamingBar from '../components/StreamingBar';
// import PopUpAd from '../components/PopUpAd';
// import NewsPopup from '../components/NewsPopup';
// import CategoryPopup from '../components/CategoryDetails';
// import { logout } from '../redux/reducers/userSlice';
// import { Link, useLocation, useNavigate } from 'react-router-dom';

// const isMobileBrowser = () => {
//   return /Mobi|Android/i.test(navigator.userAgent);
// };

// const Home = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const userData = useSelector((state) => state.user.userData);
//   const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
//   const { news, isLoading: isNewsLoading, errorMessage: newsError } = useSelector((state) => state.news);
//   const { categories, isLoading: isCategoriesLoading, errorMessage: categoriesError } = useSelector((state) => state.categories);
//   const { ads, isLoading: isAdsLoading, errorMessage: adsError } = useSelector((state) => state.ads);
//   const { rjPosters, isLoading: isRjPostersLoading, errorMessage: rjPostersError } = useSelector((state) => state.rjPosters);
//   const { streamingURLs, selectedCity } = useSelector((state) => state.streamingBar);

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [interestedCategories, setInterestedCategories] = useState([]);
//   const [showPopUpAd, setShowPopUpAd] = useState(false);
//   const [currentPopUpAd, setCurrentPopUpAd] = useState(null);
//   const [bannerAdIndex, setBannerAdIndex] = useState(0);
//   const [selectedNews, setSelectedNews] = useState(null);
//   const [showRegisterPopup, setShowRegisterPopup] = useState(false);
//   const [showCityPopup, setShowCityPopup] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [selectedRJ, setSelectedRJ] = useState(null);
//   const [isAutoPlay, setIsAutoPlay] = useState(true);
//   const [comingSoonCity, setComingSoonCity] = useState(null);

//   useEffect(() => {
//     dispatch(fetchProgramsList(userData.isGuest));
//     dispatch(fetchNewsList(userData.isGuest));
//     dispatch(fetchCategoryList(userData.isGuest));
//     dispatch(fetchAds(true));
//     dispatch(fetchRjPostersList(userData.isGuest)); // Fetch RJ posters
//     if (userData && userData.categories) {
//       setInterestedCategories(userData.categories);
//     }
//   }, [dispatch, userData]);

//   useEffect(() => {
//     const fetchStreamingList = async () => {
//       try {
//         const streamingList = await apiService.fetchStreamingList(userData.isGuest, userData);
//         dispatch(setStreamingURLs(streamingList));
//         const defaultCity = streamingList.find(city => city.streamStatus === 'default');
//         if (defaultCity) {
//           dispatch(setSelectedCity(defaultCity));
//         }
//       } catch (error) {
//         console.error('Error fetching streaming list:', error);
//       }
//     };

//     fetchStreamingList();
//   }, [dispatch, userData]);

//   const bannerAds = ads.filter(ad => ad.type === '2');

//   useEffect(() => {
//     if (bannerAds.length > 0) {
//       const showRandomBannerAd = () => {
//         const randomIndex = Math.floor(Math.random() * bannerAds.length);
//         setBannerAdIndex(randomIndex);
//       };

//       showRandomBannerAd();

//       const interval = setInterval(() => {
//         showRandomBannerAd();
//       }, 18000);

//       return () => clearInterval(interval);
//     }
//   }, [bannerAds]);

//   const currentBannerAd = bannerAds[bannerAdIndex];

//   const toggleInterest = async (categoryId) => {
//     try {
//       const accessToken = userData.identityToken;
//       const userId = userData.id;

//       const response = await apiService.toggleFavorite(userId, accessToken, categoryId);

//       if (response) {
//         setInterestedCategories((prevState) =>
//           prevState.includes(categoryId)
//             ? prevState.filter((id) => id !== categoryId)
//             : [...prevState, categoryId]
//         );
//       } else {
//         throw new Error('Failed to favorite the category');
//       }
//     } catch (error) {
//       console.error('Error favoriting category:', error);
//     }
//   };

//   const handleNewsClick = (newsItem) => {
//     setSelectedNews(newsItem);
//   };

//   const closeNewsPopup = () => {
//     setSelectedNews(null);
//   };

//   const handleRestrictedNavigation = (e) => {
//     if (userData.isGuest) {
//       e.preventDefault();
//       setShowRegisterPopup(true);
//     }
//   };

//   const closeRegisterPopup = () => {
//     setShowRegisterPopup(false);
//   };

//   const handleCategoryClick = (categoryId) => {
//     if (userData.isGuest) {
//       setShowRegisterPopup(true);
//     } else {
//       setSelectedCategory(categoryId);
//     }
//   };

//   const closeCategoryPopup = () => {
//     setSelectedCategory(null);
//   };

//   const handleRJClick = (poster) => {
//     if (isMobileBrowser()) {
//       setSelectedRJ(poster);
//       setIsAutoPlay(false);
//     }
//   };

//   const closeRJPopup = (e) => {
//     if (e.target.id === 'rj-popup-container' || e.target.tagName === 'BUTTON') {
//       setSelectedRJ(null);
//       setIsAutoPlay(true);
//     }
//   };

//   if (!userData) {
//     return <p>Loading...</p>;
//   }

//   const carouselSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//     centerMode: true,
//     centerPadding: "0",
//     prevArrow: <CustomPrevArrow />,
//     nextArrow: <CustomNextArrow />
//   };

//   function CustomPrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <FaArrowLeft
//         className={`${className} custom-arrow`}
//         style={{ ...style, display: "block", position: "absolute", left: "10px", zIndex: 1 }}
//         onClick={onClick}
//       />
//     );
//   }

//   function CustomNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <FaArrowRight
//         className={`${className} custom-arrow`}
//         style={{ ...style, display: "block", position: "absolute", right: "10px", zIndex: 1 }}
//         onClick={onClick}
//       />
//     );
//   }

//   const handleLoginClick = () => {
//     dispatch(logout());
//     closeRegisterPopup();
//     navigate('/login');
//   };

//   const handleSignupClick = () => {
//     dispatch(logout());
//     closeRegisterPopup();
//     navigate('/signup');
//   };

//   const handleCityClick = (city) => {
//     if (city.streamStatus === 'coming soon') {
//       setComingSoonCity(city.streamingCity);
//     } else {
//       dispatch(setSelectedCity(city));
//     }
//   };

//   const closeCityPopup = () => {
//     setShowCityPopup(false);
//   };

//   const closeComingSoonPopup = () => {
//     setComingSoonCity(null);
//   };

//   const renderCityButtons = () => {
//     const defaultCity = streamingURLs.find(city => city.streamStatus === 'default');
//     const activeCity = streamingURLs.find(city => city.streamStatus === 'active');
//     const comingSoonCity = streamingURLs.find(city => city.streamStatus === 'coming soon');
//     const otherCities = streamingURLs.filter(city => city.streamStatus !== 'default' && city.streamStatus !== 'active' && city.streamStatus !== 'coming soon');

//     if (streamingURLs.length <= 3) {
//       return (
//         <div className="flex justify-center pt-8 md:space-x-12 space-x-8">
//           {streamingURLs.map(city => (
//             <button
//               key={city.PK}
//               className={`rounded-lg py-2 px-4 ${selectedCity === city ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
//               onClick={() => handleCityClick(city)}
//             >
//               {city.streamingCity}
//             </button>
//           ))}
//         </div>
//       );
//     } else {
//       return (
//         <div className="flex justify-center space-x-4">
//           {defaultCity && (
//             <button
//               className={`rounded-lg py-2 px-4 ${selectedCity === defaultCity ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
//               onClick={() => handleCityClick(defaultCity)}
//             >
//               {defaultCity.streamingCity}
//             </button>
//           )}
//           {activeCity && (
//             <button
//               className={`rounded-lg py-2 px-4 ${selectedCity === activeCity ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
//               onClick={() => handleCityClick(activeCity)}
//             >
//               {activeCity.streamingCity}
//             </button>
//           )}
//           {comingSoonCity && (
//             <button
//               className={`rounded-lg py-2 px-4 ${selectedCity === comingSoonCity ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
//               onClick={() => handleCityClick(comingSoonCity)}
//             >
//               {comingSoonCity.streamingCity}
//             </button>
//           )}
//           <button
//             className="rounded-lg py-2 px-4 bg-gray-200 text-gray-800"
//             onClick={() => setShowCityPopup(true)}
//           >
//             Other Cities
//           </button>
//         </div>
//       );
//     }
//   };

//   return (
//     <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
//       {selectedNews && (
//         <NewsPopup news={selectedNews} onClose={closeNewsPopup} />
//       )}
//       {showRegisterPopup && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
//             <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
//             <div className="flex space-x-4 justify-center">
//               <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleLoginClick}>Login</button>
//               <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleSignupClick}>Signup</button>
//             </div>
//             <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>Close</button>
//           </div>
//         </div>
//       )}
//       {selectedCategory && (
//         <CategoryPopup
//           userData={userData}
//           categoryId={selectedCategory}
//           onClose={closeCategoryPopup}
//         />
//       )}
//       {selectedRJ && isMobileBrowser() && (
//         <div id="rj-popup-container" className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeRJPopup}>
//           <div className="relative bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
//             <button className="absolute top-2 right-2 text-gray-900 dark:text-gray-100" onClick={closeRJPopup}>
//               <FaTimes />
//             </button>
//             <img src={selectedRJ.thumbnail} alt={selectedRJ.rjName} className="w-full h-auto object-cover rounded-lg" />
//           </div>
//         </div>
//       )}
//       {showCityPopup && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
//             <h2 className="text-xl font-bold mb-4">Select a City</h2>
//             <ul className="text-left">
//               {streamingURLs.map(city => (
//                 <li key={city.PK} className="flex justify-between items-center mb-2">
//                   <span className="text-gray-800 dark:text-gray-200">{city.streamingCity}</span>
//                   {city.streamStatus === 'active' ? (
//                     <button
//                       className="bg-green-500 text-white px-4 py-2 rounded-md"
//                       onClick={() => {
//                         setShowCityPopup(false);
//                         handleCityClick(city);
//                       }}
//                     >
//                       Listen
//                     </button>
//                   ) : (
//                     <span className="text-blue-600">Coming Soon</span>
//                   )}
//                 </li>
//               ))}
//             </ul>
//             <button className="mt-4 text-gray-500" onClick={closeCityPopup}>Close</button>
//           </div>
//         </div>
//       )}
//       {comingSoonCity && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
//             <p className="text-gray-900 dark:text-gray-100 font-bold mb-4">We are coming to <span className="text-blue-600">{comingSoonCity}</span> soon</p>
//             <button className="mt-4 text-gray-500" onClick={closeComingSoonPopup}>Close</button>
//           </div>
//         </div>
//       )}
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
//         <div className="relative h-80">
//           <img src={banner} alt="Banner" className="w-full h-80 object-cover" />
//             <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-gray-50 dark:to-gray-900"></div>
//         </div>

//         <div className="text-center pt-8 md:pt-12 -mt-12">
//           <h1 className="text-xl md:text-4xl font-bold font-poppins text-gray-500 dark:text-gray-500">North America's</h1>
//           <h2 className="text-xl md:text-4xl font-bold font-poppins text-gray-500 dark:text-gray-500">
//             First ever <span className="text-blue-600">24x7</span> Telugu Channel
//           </h2>
//         </div>

//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Today's <span className="text-blue-600">Shows</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">You shouldn't miss today</p>
//           {isProgramsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
//           ) : programsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {programs.map((program) => (
//                     <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
//                       <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         <div className="px-4 pb-12 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Select <span className="text-blue-600">Cities</span>
//           </h2>
//           {renderCityButtons()}
//         </div>

//         {/* News and Categories Section */}
//         <div className="px-4 md:px-16 pt-0 md:pt-8 bg-gray-50 dark:bg-gray-900 flex flex-col md:flex-row space-y-16 md:space-y-0 md:space-x-16">
//           {/* Latest News Section */}
//           <div className="w-full md:w-1/2 pr-2">
//             <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//               Latest <span className="text-blue-600">News</span>
//             </h2>
//             <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Stay updated with the latest news</p>
//             {isNewsLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading news...</p>
//             ) : newsError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading news: {newsError}</p>
//             ) : (
//               <div className="relative">
//                 <div className="no-scrollbar overflow-x-scroll">
//                   <div className="flex space-x-6">
//                     {news.map((item) => (
//                       <div key={item.SK} className="min-w-[220px] max-w-[200px]" onClick={() => handleNewsClick(item)}>
//                         <img src={item.thumbnail} alt={item.title} className="w-full h-auto object-cover rounded-lg" />
//                         <div className="pt-2">
//                           <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{item.title}</h3>
//                           <p className="text-gray-600 dark:text-gray-400">{new Date(item.updatedAt).toLocaleDateString()}</p>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
          
//           {/* Categories Section */}
//           <div className="w-full md:w-1/2 pl-2 hidden md:block">
//             <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//               Categories
//             </p>
//             {isCategoriesLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//             ) : categoriesError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//             ) : (
//               <div className="relative h-72 overflow-y-scroll no-scrollbar">
//                 <div className="flex flex-col space-y-6">
//                   {categories.map((category) => (
//                     <div key={category.PK} className="flex items-center space-x-4" onClick={() => handleCategoryClick(category.PK)}>
//                       <img src={category.thumbnail} alt={category.title} className="w-16 h-16 object-cover rounded-lg" />
//                       <div className="ml-4 flex-1">
//                         <h3 className="text-l font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{category.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">Shows: {category.programCount}</p>
//                       </div>
//                       <button onClick={(e) => {e.stopPropagation(); toggleInterest(category.PK);}} className="ml-4">
//                         {interestedCategories.includes(category.PK) ? (
//                           <FaHeart className="text-red-500" />
//                         ) : (
//                           <FaRegHeart className="text-gray-400" />
//                         )}
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Banner Ads Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           {isAdsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
//           ) : adsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
//           ) : (
//             <div className="relative">
//               {currentBannerAd && (
//                 <div className="flex justify-center items-center">
//                   <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
//                     <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-auto h-auto object-cover rounded-lg" />
//                   </a>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>

//         {/* Categories Section for Mobile */}
//         <div className="px-4 md:px-16 pt-4 md:hidden bg-gray-50 dark:bg-gray-900">
//           <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//             Categories
//           </p>
//           {isCategoriesLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//           ) : categoriesError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//           ) : (
//             <div className="relative no-scrollbar overflow-x-scroll">
//               <div className="flex space-x-6">
//                 {categories.map((category) => (
//                   <div key={category.PK} className="flex-shrink-0" onClick={() => handleCategoryClick(category.PK)}>
//                     <img src={category.thumbnail} alt={category.title} className="w-40 h-40 object-cover rounded-lg" />
//                     <p className="text-center text-gray-900 dark:text-gray-100 mt-2">{category.title}</p>
//                     <button onClick={(e) => {e.stopPropagation(); toggleInterest(category.PK);}} className="block mx-auto mt-2">
//                       {interestedCategories.includes(category.PK) ? (
//                         <FaHeart className="text-red-500" />
//                       ) : (
//                         <FaRegHeart className="text-gray-400" />
//                       )}
//                     </button>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Our RJs Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Our <span className="text-blue-600">RJs</span>
//           </h2>
//           <div className="relative carousel-wrapper">
//             {isRjPostersLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading RJ posters...</p>
//             ) : rjPostersError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading RJ posters: {rjPostersError}</p>
//             ) : (
//               <Slider {...carouselSettings}>
//                 {rjPosters.map((poster, index) => (
//                   <div key={index} className="p-4 md:p-14">
//                     <div className="carousel-item">
//                       <img 
//                         src={poster.thumbnail} // Assuming the poster object has a 'thumbnail' property
//                         alt={poster.rjName} // Assuming the poster object has a 'rjName' property
//                         className="w-full h-full object-cover rounded-lg" 
//                         onClick={() => handleRJClick(poster)} // Add onClick event for mobile
//                       />
//                     </div>
//                   </div>
//                 ))}
//               </Slider>
//             )}
//           </div>
//         </div>
        
//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// export default Home;


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProgramsList } from '../redux/actions/programsActions';
import { fetchNewsList } from '../redux/actions/newsActions';
import { fetchCategoryList } from '../redux/actions/categoriesActions';
import { fetchAds } from '../redux/actions/adsActions';
import { fetchRjPostersList } from '../redux/actions/rjPostersActions';
import { setStreamingURLs, setSelectedCity } from '../redux/reducers/streamingBarSlice';
import apiService from '../redux/api-service/apiService';
import NavigationBar from '../components/NavigationBar';
import banner from '../assets/cover 007.png';
import "../styles/global.css";
import "../styles/carousel.css";
import { FaHeart, FaRegHeart, FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';
import Slider from "react-slick";
import StreamingBar from '../components/StreamingBar';
import PopUpAd from '../components/PopUpAd';
import NewsPopup from '../components/NewsPopup';
import CategoryPopup from '../components/CategoryDetails';
import { logout } from '../redux/reducers/userSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const isMobileBrowser = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);
  const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
  const { news, isLoading: isNewsLoading, errorMessage: newsError } = useSelector((state) => state.news);
  const { categories, isLoading: isCategoriesLoading, errorMessage: categoriesError } = useSelector((state) => state.categories);
  const { ads, isLoading: isAdsLoading, errorMessage: adsError } = useSelector((state) => state.ads);
  const { rjPosters, isLoading: isRjPostersLoading, errorMessage: rjPostersError } = useSelector((state) => state.rjPosters);
  const { streamingURLs, selectedCity } = useSelector((state) => state.streamingBar);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [interestedCategories, setInterestedCategories] = useState([]);
  const [showPopUpAd, setShowPopUpAd] = useState(false);
  const [currentPopUpAd, setCurrentPopUpAd] = useState(null);
  const [bannerAdIndex, setBannerAdIndex] = useState(0);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showCityPopup, setShowCityPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedRJ, setSelectedRJ] = useState(null);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const [comingSoonCity, setComingSoonCity] = useState(null);

  useEffect(() => {
    dispatch(fetchProgramsList(userData.isGuest));
    dispatch(fetchNewsList(userData.isGuest));
    dispatch(fetchCategoryList(userData.isGuest));
    dispatch(fetchAds(true));
    dispatch(fetchRjPostersList(userData.isGuest)); // Fetch RJ posters
    const fetchStreamingList = async () => {
      try {
        const streamingList = await apiService.fetchStreamingList(userData.isGuest, userData);
        dispatch(setStreamingURLs(streamingList));
        const defaultCity = streamingList.find(city => city.streamStatus === 'default');
        if (defaultCity) {
          dispatch(setSelectedCity(defaultCity));
        }
      } catch (error) {
        console.error('Error fetching streaming list:', error);
      }
    };

    fetchStreamingList();
    if (userData && userData.categories) {
      setInterestedCategories(userData.categories);
    }
  }, [dispatch, userData]);

  // useEffect(() => {
  //   const fetchStreamingList = async () => {
  //     try {
  //       const streamingList = await apiService.fetchStreamingList(userData.isGuest, userData);
  //       dispatch(setStreamingURLs(streamingList));
  //       const defaultCity = streamingList.find(city => city.streamStatus === 'default');
  //       if (defaultCity) {
  //         dispatch(setSelectedCity(defaultCity));
  //       }
  //     } catch (error) {
  //       console.error('Error fetching streaming list:', error);
  //     }
  //   };

  //   fetchStreamingList();
  // }, [dispatch, userData]);

  const bannerAds = ads.filter(ad => ad.type === '2');

  useEffect(() => {
    if (bannerAds.length > 0) {
      const showRandomBannerAd = () => {
        const randomIndex = Math.floor(Math.random() * bannerAds.length);
        setBannerAdIndex(randomIndex);
      };

      showRandomBannerAd();

      const interval = setInterval(() => {
        showRandomBannerAd();
      }, 18000);

      return () => clearInterval(interval);
    }
  }, [bannerAds]);

  const currentBannerAd = bannerAds[bannerAdIndex];

  const toggleInterest = async (categoryId) => {
    try {
      const accessToken = userData.identityToken;
      const userId = userData.id;

      const response = await apiService.toggleFavorite(userId, accessToken, categoryId);

      if (response) {
        setInterestedCategories((prevState) =>
          prevState.includes(categoryId)
            ? prevState.filter((id) => id !== categoryId)
            : [...prevState, categoryId]
        );
      } else {
        throw new Error('Failed to favorite the category');
      }
    } catch (error) {
      console.error('Error favoriting category:', error);
    }
  };

  const handleNewsClick = (newsItem) => {
    setSelectedNews(newsItem);
  };

  const closeNewsPopup = () => {
    setSelectedNews(null);
  };

  const handleRestrictedNavigation = (e) => {
    if (userData.isGuest) {
      e.preventDefault();
      setShowRegisterPopup(true);
    }
  };

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
  };

  const handleCategoryClick = (categoryId) => {
    if (userData.isGuest) {
      setShowRegisterPopup(true);
    } else {
      setSelectedCategory(categoryId);
    }
  };

  const closeCategoryPopup = () => {
    setSelectedCategory(null);
  };

  const handleRJClick = (poster) => {
    if (isMobileBrowser()) {
      setSelectedRJ(poster);
      setIsAutoPlay(false);
    }
  };

  const closeRJPopup = (e) => {
    if (e.target.id === 'rj-popup-container' || e.target.tagName === 'BUTTON') {
      setSelectedRJ(null);
      setIsAutoPlay(true);
    }
  };

  if (!userData) {
    return <p>Loading...</p>;
  }

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    centerMode: true,
    centerPadding: "0",
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FaArrowLeft
        className={`${className} custom-arrow`}
        style={{ ...style, display: "block", position: "absolute", left: "10px", zIndex: 1 }}
        onClick={onClick}
      />
    );
  }

  function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FaArrowRight
        className={`${className} custom-arrow`}
        style={{ ...style, display: "block", position: "absolute", right: "10px", zIndex: 1 }}
        onClick={onClick}
      />
    );
  }

  const handleLoginClick = () => {
    dispatch(logout());
    closeRegisterPopup();
    navigate('/login');
  };

  const handleSignupClick = () => {
    dispatch(logout());
    closeRegisterPopup();
    navigate('/signup');
  };

  const handleCityClick = (city) => {
    if (city.streamStatus === 'coming soon') {
      setComingSoonCity(city.streamingCity);
    } else {
      dispatch(setSelectedCity(city));
    }
  };

  const closeCityPopup = () => {
    setShowCityPopup(false);
  };

  const closeComingSoonPopup = () => {
    setComingSoonCity(null);
  };

  const renderCityButtons = () => {
    const defaultCity = streamingURLs.find(city => city.streamStatus === 'default');
    const activeCity = streamingURLs.find(city => city.streamStatus === 'active');
    const comingSoonCity = streamingURLs.find(city => city.streamStatus === 'coming soon');
    const otherCities = streamingURLs.filter(city => city.streamStatus !== 'default' && city.streamStatus !== 'active' && city.streamStatus !== 'coming soon');

    if (streamingURLs.length <= 3) {
      return (
        <div className="flex justify-center pt-8 md:space-x-12 space-x-8">
          {streamingURLs.map(city => (
            <button
              key={city.PK}
              className={`rounded-lg py-2 px-4 ${selectedCity === city ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
              onClick={() => handleCityClick(city)}
            >
              {city.streamingCity}
            </button>
          ))}
        </div>
      );
    } else {
      return (
        <div className="flex justify-center space-x-4">
          {defaultCity && (
            <button
              className={`rounded-lg py-2 px-4 ${selectedCity === defaultCity ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
              onClick={() => handleCityClick(defaultCity)}
            >
              {defaultCity.streamingCity}
            </button>
          )}
          {activeCity && (
            <button
              className={`rounded-lg py-2 px-4 ${selectedCity === activeCity ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
              onClick={() => handleCityClick(activeCity)}
            >
              {activeCity.streamingCity}
            </button>
          )}
          {comingSoonCity && (
            <button
              className={`rounded-lg py-2 px-4 ${selectedCity === comingSoonCity ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
              onClick={() => handleCityClick(comingSoonCity)}
            >
              {comingSoonCity.streamingCity}
            </button>
          )}
          <button
            className="rounded-lg py-2 px-4 bg-gray-200 text-gray-800"
            onClick={() => setShowCityPopup(true)}
          >
            Other Cities
          </button>
        </div>
      );
    }
  };

  return (
    <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
      {selectedNews && (
        <NewsPopup news={selectedNews} onClose={closeNewsPopup} />
      )}
      {showRegisterPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
            <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
            <div className="flex space-x-4 justify-center">
              <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleLoginClick}>Login</button>
              <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleSignupClick}>Signup</button>
            </div>
            <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>Close</button>
          </div>
        </div>
      )}
      {selectedCategory && (
        <CategoryPopup
          userData={userData}
          categoryId={selectedCategory}
          onClose={closeCategoryPopup}
        />
      )}
      {selectedRJ && isMobileBrowser() && (
        <div id="rj-popup-container" className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeRJPopup}>
          <div className="relative bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
            <button className="absolute top-2 right-2 text-gray-900 dark:text-gray-100" onClick={closeRJPopup}>
              <FaTimes />
            </button>
            <img src={selectedRJ.thumbnail} alt={selectedRJ.rjName} className="w-full h-auto object-cover rounded-lg" />
          </div>
        </div>
      )}
      {showCityPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold mb-4">Select a City</h2>
            <ul className="text-left">
              {streamingURLs.map(city => (
                <li key={city.PK} className="flex justify-between items-center mb-2">
                  <span className="text-gray-800 dark:text-gray-200">{city.streamingCity}</span>
                  {city.streamStatus === 'active' ? (
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded-md"
                      onClick={() => {
                        setShowCityPopup(false);
                        handleCityClick(city);
                      }}
                    >
                      Listen
                    </button>
                  ) : (
                    <span className="text-blue-600">Coming Soon</span>
                  )}
                </li>
              ))}
            </ul>
            <button className="mt-4 text-gray-500" onClick={closeCityPopup}>Close</button>
          </div>
        </div>
      )}
      {comingSoonCity && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
            <p className="text-gray-900 dark:text-gray-100 font-bold mb-4">We are coming to <span className="text-blue-600">{comingSoonCity}</span> soon</p>
            <button className="mt-4 text-gray-500" onClick={closeComingSoonPopup}>Close</button>
          </div>
        </div>
      )}
      <div className="fixed h-screen z-50">
        <NavigationBar setIsCollapsed={setIsCollapsed} />
      </div>
      <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
      <div className="relative h-80">
        <img src={banner} alt="Banner" className="w-full h-80 object-cover" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-gray-50 dark:to-gray-900"></div>
          <div className="absolute top-48 left-1/2 transform -translate-x-1/2 translate-y-8 text-center w-4/5 md:w-3/5 mx-auto">
            <h1 className="text-xl md:text-4xl font-bold font-poppins text-gray-800 dark:text-gray-400 " data-text="North America's">
              North America's
            </h1>
            <h2 className="text-xl md:text-4xl font-bold font-poppins text-gray-800 dark:text-gray-400 " data-text="First ever 24x7 Telugu Channel">
              First ever <span className="text-blue-600">24x7</span> Telugu Channel
            </h2>
          </div>
      </div>


        {/* <div className="text-center pt-8 md:pt-12 -mt-12">
          <h1 className="text-xl md:text-4xl font-bold font-poppins text-gray-500 dark:text-gray-500">North America's</h1>
          <h2 className="text-xl md:text-4xl font-bold font-poppins text-gray-500 dark:text-gray-500">
            First ever <span className="text-blue-600">24x7</span> Telugu Channel
          </h2>
        </div> */}

        <div className="px-4 md:px-16 pt-4 md:pt-0 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Today's <span className="text-blue-600">Shows</span>
          </h2>
          <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">You shouldn't miss today</p>
          {isProgramsLoading ? (
            <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
          ) : programsError ? (
            <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
          ) : (
            <div className="relative">
              <div className="no-scrollbar overflow-x-scroll">
                <div className="flex space-x-6">
                  {programs.map((program) => (
                    <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
                      <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
                      <div className="p-2">
                        <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="px-4 pb-12 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Select <span className="text-blue-600">Cities</span>
          </h2>
          {renderCityButtons()}
        </div>

        {/* News and Categories Section */}
        <div className="px-4 md:px-16 pt-0 md:pt-8 bg-gray-50 dark:bg-gray-900 flex flex-col md:flex-row space-y-16 md:space-y-0 md:space-x-16">
          {/* Latest News Section */}
          <div className="w-full md:w-1/2 pr-2">
            <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
              Latest <span className="text-blue-600">News</span>
            </h2>
            <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Stay updated with the latest news</p>
            {isNewsLoading ? (
              <p className="text-gray-900 dark:text-gray-100">Loading news...</p>
            ) : newsError ? (
              <p className="text-red-600 dark:text-red-400">Error loading news: {newsError}</p>
            ) : (
              <div className="relative">
                <div className="no-scrollbar overflow-x-scroll">
                  <div className="flex space-x-6">
                    {news.map((item) => (
                      <div key={item.SK} className="min-w-[220px] max-w-[200px]" onClick={() => handleNewsClick(item)}>
                        <img src={item.thumbnail} alt={item.title} className="w-full h-auto object-cover rounded-lg" />
                        <div className="pt-2">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{item.title}</h3>
                          <p className="text-gray-600 dark:text-gray-400">{new Date(item.updatedAt).toLocaleDateString()}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          
          {/* Categories Section */}
          <div className="w-full md:w-1/2 pl-2 hidden md:block">
            <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
              Categories
            </p>
            {isCategoriesLoading ? (
              <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
            ) : categoriesError ? (
              <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
            ) : (
              <div className="relative h-72 overflow-y-scroll no-scrollbar">
                <div className="flex flex-col space-y-6">
                  {categories.map((category) => (
                    <div key={category.PK} className="flex items-center space-x-4" onClick={() => handleCategoryClick(category.PK)}>
                      <img src={category.thumbnail} alt={category.title} className="w-16 h-16 object-cover rounded-lg" />
                      <div className="ml-4 flex-1">
                        <h3 className="text-l font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{category.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">Shows: {category.programCount}</p>
                      </div>
                      <button onClick={(e) => {e.stopPropagation(); toggleInterest(category.PK);}} className="ml-4">
                        {interestedCategories.includes(category.PK) ? (
                          <FaHeart className="text-red-500" />
                        ) : (
                          <FaRegHeart className="text-gray-400" />
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Banner Ads Section */}
        <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
          {isAdsLoading ? (
            <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
          ) : adsError ? (
            <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
          ) : (
            <div className="relative">
              {currentBannerAd && (
                <div className="flex justify-center items-center">
                  <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
                    <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-auto h-auto object-cover rounded-lg" />
                  </a>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Categories Section for Mobile */}
        <div className="px-4 md:px-16 pt-4 md:hidden bg-gray-50 dark:bg-gray-900">
          <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
            Categories
          </p>
          {isCategoriesLoading ? (
            <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
          ) : categoriesError ? (
            <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
          ) : (
            <div className="relative no-scrollbar overflow-x-scroll">
              <div className="flex space-x-6">
                {categories.map((category) => (
                  <div key={category.PK} className="flex-shrink-0" onClick={() => handleCategoryClick(category.PK)}>
                    <img src={category.thumbnail} alt={category.title} className="w-40 h-40 object-cover rounded-lg" />
                    <p className="text-center text-gray-900 dark:text-gray-100 mt-2">{category.title}</p>
                    <button onClick={(e) => {e.stopPropagation(); toggleInterest(category.PK);}} className="block mx-auto mt-2">
                      {interestedCategories.includes(category.PK) ? (
                        <FaHeart className="text-red-500" />
                      ) : (
                        <FaRegHeart className="text-gray-400" />
                      )}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Our RJs Section */}
        <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Our <span className="text-blue-600">RJs</span>
          </h2>
          <div className="relative carousel-wrapper">
            {isRjPostersLoading ? (
              <p className="text-gray-900 dark:text-gray-100">Loading RJ posters...</p>
            ) : rjPostersError ? (
              <p className="text-red-600 dark:text-red-400">Error loading RJ posters: {rjPostersError}</p>
            ) : (
              <Slider {...carouselSettings}>
                {rjPosters.map((poster, index) => (
                  <div key={index} className="p-4 md:p-14">
                    <div className="carousel-item">
                      <img 
                        src={poster.thumbnail} // Assuming the poster object has a 'thumbnail' property
                        alt={poster.rjName} // Assuming the poster object has a 'rjName' property
                        className="w-full h-full object-cover rounded-lg" 
                        onClick={() => handleRJClick(poster)} // Add onClick event for mobile
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
        
        {/* Add the StreamingBar component here */}
        <StreamingBar isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export default Home;
