// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { loginSuccess, logout } from '../redux/reducers/userSlice';
// import { fetchAuthSession } from 'aws-amplify/auth';

// const Landing = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const getData = async () => {
//       const accessToken = localStorage.getItem('accessToken');
//       const refreshToken = localStorage.getItem('refreshToken');
//       const userId = localStorage.getItem('userId');
//       const isGuest = JSON.parse(localStorage.getItem('isGuest'));

//       if (accessToken && accessToken !== 'accessToken' && userId) {
//         dispatch(loginSuccess({ accessToken, refreshToken, userId, isGuest }));

//         try {
//           const session = await fetchAuthSession();
//           if (session && session.accessToken && session.idToken) {
//             navigate('/home');
//           } else {
//             throw new Error('Session invalid');
//           }
//         } catch (error) {
//           dispatch(logout());
//           navigate('/login');
//         }
//       } else {
//         navigate('/login');
//       }

//       setIsLoading(false);
//     };

//     getData();
//   }, [dispatch, navigate]);

//   if (isLoading) {
//     return <div>Loading...</div>; // Display a loading spinner if necessary
//   }

//   return null;
// };

// export default Landing;

// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { loginSuccess, logout, setGuest } from '../redux/reducers/userSlice';
// import { fetchAuthSession } from 'aws-amplify/auth';

// const Landing = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const getData = async () => {
//       const accessToken = localStorage.getItem('accessToken');
//       const refreshToken = localStorage.getItem('refreshToken');
//       const userId = localStorage.getItem('userId');
//       const isGuest = JSON.parse(localStorage.getItem('isGuest'));
//       const isFirstTime = !localStorage.getItem('isFirstTime');

//       if (isFirstTime) {
//         // First time user, set guest login
//         dispatch(setGuest());
//         navigate('/home');
//         localStorage.setItem('isFirstTime', 'false');
//       } else if (accessToken && accessToken !== 'accessToken' && userId) {
//         dispatch(loginSuccess({ accessToken, refreshToken, userId, isGuest }));

//         try {
//           const session = await fetchAuthSession();
//           if (session && session.accessToken && session.idToken) {
//             navigate('/home');
//           } else {
//             throw new Error('Session invalid');
//           }
//         } catch (error) {
//           dispatch(logout());
//           navigate('/login');
//         }
//       } else {
//         navigate('/login');
//       }

//       setIsLoading(false);
//     };

//     getData();
//   }, [dispatch, navigate]);

//   if (isLoading) {
//     return <div>Loading...</div>; // Display a loading spinner if necessary
//   }

//   return null;
// };

// export default Landing;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, logout, setGuest } from '../redux/reducers/userSlice';
import { fetchAuthSession } from 'aws-amplify/auth';

const Landing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    const getData = async () => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const userId = localStorage.getItem('userId');
      const isGuest = JSON.parse(localStorage.getItem('isGuest'));

      if (!userData) {
        // No user data available, set guest login
        dispatch(setGuest());
        navigate('/home');
      } else if (accessToken && accessToken !== 'accessToken' && userId) {
        dispatch(loginSuccess({ accessToken, refreshToken, userId, isGuest }));

        try {
          const session = await fetchAuthSession();
          if (userData && session && session.accessToken && session.idToken) {
            navigate('/home');
          } else {
            throw new Error('Session invalid');
          }
        } catch (error) {
          dispatch(logout());
          navigate('/login');
        }
      } else {
        navigate('/login');
      }

      setIsLoading(false);
    };

    getData();
  }, [dispatch, navigate, userData]);

  if (isLoading) {
    return <div>Loading...</div>; // Display a loading spinner if necessary
  }

  return null;
};

export default Landing;
