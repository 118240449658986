import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigationBar from '../components/NavigationBar';
import banner from '../assets/cover 007.png';
import "../styles/global.css";
import "../styles/carousel.css"; // Add a new CSS file for carousel styles
import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component

const Privacy = () => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
  }, [dispatch]);

  return (
    <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
      <div className="fixed h-screen z-50">
        <NavigationBar setIsCollapsed={setIsCollapsed} />
      </div>
      <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
        {/* Banner for web version */}
        <div className="hidden md:block relative h-96">
          <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
        </div>

        <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Terms of Use & Privacy Policy
          </h2>
          <div className="flex flex-col justify-between mb-16 pt-10">
            <div className="w-full pr-8">
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">
                We respect our visitor's right to privacy and will protect it when you visit www.radioSurabhi.com. We use email addresses only respond to your requests and queries. We do not share or sell your email address and any other information with third party companies. To ensure easy accessibility and enhance your experience, the site may use cookies. Cookies of this site do not contain any personal identifying information. Indeed, these cookies help us provide you with a better service, by enabling us to monitor which pages you find interesting. By using the site you are agreeing to our Terms of Use.
              </p>
              <h1 className='text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]'>Disclaimer:</h1>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">The content and functionality on the site is provided with the understanding that Radio Surabhi is not herein engaged in rendering professional advice and services to the users. Information is provided to the user on an 'as is' and 'as available' basis and the sites disclaim all responsibility/ liability for the same. In case you consider the information as reliable one then therefore it strictly at your own risk.</p>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">All information including texts, images, logos, directories, graphics, articles, videos, etc displayed, transmitted or carried on Radio Surabhi.com is owned and protected by copyright and other intellectual property laws. By accessing the site, a user agrees to use the information only for personal use, not to alter the information any way. They are not allowed to modify, publish, transmit, transfer, sell, reproduce, create derivative work from, distribute, repost, perform, display or in any way commercially exploit any of the Content.</p>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">Radio Surabhi and its third parties makes no warranties as to accuracy, completeness, timeliness, presentation or aptness of the information provided on the site for any purpose. Those information and materials may contain inaccuracies or errors and we are not liable for such inaccuracies permitted by law. Neither we nor third parties content providers shall be liable for any indirect, incidental, consequential, or damages or for lost revenues or profits.</p>
              <h1 className='text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]'>Terms of Use:</h1>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">We at Radio Surabhi.com, express our heartiest welcome to our visitors. Radio Surabhi Provides access and use of its website as www.radiosurabhi.com (Site), subjects to your agreement to the following "Terms of Use". Services provided on and by our website are strictly subjects to the terms of the company. So, please read these Terms and carefully before accessing or using the site. By accessing or using the site, you agree to be complying with these terms.Copyrights.</p>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">All site designs, texts, graphics, logos, icons and images thereof are exclusive property of Radio Surabhi or its licensors and are protected by US and International copyright laws. Users do not have any right to reused in any way, without taking prior written permission from Radio Surabhi.</p>
              <h1 className='text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]'>Trademarks:</h1>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">The trademarks including designs, logos, service marks displayed on the site are registered and unregistered trademarks of Radio Surabhi and its licensors. You agree that you will not refer or publish any information to Radio Surabhi in any other public media including websites, press releases, etc. for advertising or promotional purposes. Use of any trademark without written consent will be a violation of Radio Surabhi's trademark and other intellectual property rights.
              </p>
              <h1 className='text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]'>Use of Site Content:</h1>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">The content of the pages of the Site is for your general information and use only and owned by or licensed to Radio Surabhi . It is subject to change without any prior notice. We may review, edit, reject, decline to post and/or delete any Content that in the sole decision of Radio Surabhi.com violate these Terms of Use or which might be offensive, illegal. You may not otherwise reproduce, modify, distribute, transmit, post, or disclose the Site Content without prior written consent of Radio Surabhi .</p>
              <h1 className='text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]'>Third Party Website links:</h1>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">This site may provide links to other web sites or you may be connected through other linked web sites. These links are provided are for your convenience to gain further information. Access them at your own risk and we are not responsible for the content of the linked sites. These links do not signify that we endorsed the sites.</p>
              <p className="text-l text-justify text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em] pl-0 md:pl-10">Reach out to us for any queries <span className='font-extrabold'>info@radiosurabhi.com.</span></p>  
            </div>
          </div>
        </div>

        {/* Add the StreamingBar component here */}
        <StreamingBar isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export default Privacy;
