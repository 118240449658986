// src/redux/reducers/rjPostersSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rjPosters: [],
  isLoading: false,
  errorMessage: '',
};

const rjPostersSlice = createSlice({
  name: 'rjPosters',
  initialState,
  reducers: {
    fetchRjPostersStart(state) {
      state.isLoading = true;
    },
    fetchRjPostersSuccess(state, action) {
      state.isLoading = false;
      state.rjPosters = action.payload;
    },
    fetchRjPostersFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { fetchRjPostersStart, fetchRjPostersSuccess, fetchRjPostersFailure } = rjPostersSlice.actions;
export default rjPostersSlice.reducer;
