import React from 'react';
import { useSpring, animated } from 'react-spring';

const RotatingImageWidget = ({ imageUrl }) => {
  const props = useSpring({
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
    config: { duration: 60000 },
    loop: { reverse: false },
  });

  return (
    <animated.div style={props} className="relative w-full md:w-80 h-80 rounded-full shadow-lg overflow-hidden">
      <img src={imageUrl} alt="Current Program" className="w-full h-full object-cover" />
    </animated.div>
  );
};

export default RotatingImageWidget;
