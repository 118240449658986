// src/redux/reducers/sponsorsSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchSponsorsList } from '../actions/sponsorsActions';

const sponsorsSlice = createSlice({
  name: 'sponsors',
  initialState: {
    sponsors: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSponsorsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSponsorsList.fulfilled, (state, action) => {
        state.loading = false;
        state.sponsors = action.payload;
      })
      .addCase(fetchSponsorsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default sponsorsSlice.reducer;
