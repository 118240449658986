// // src/redux/actions/adsActions.js
// import { fetchAdsStart, fetchAdsSuccess, fetchAdsFailure } from '../reducers/adsSlice';
// import apiService from '../api-service/apiService';

// export const fetchAds = (forGuest) => async (dispatch) => {
//   dispatch(fetchAdsStart());

//   try {
//     const ads = await apiService.fetchAds(forGuest);
//     dispatch(fetchAdsSuccess(ads));
//   } catch (error) {
//     dispatch(fetchAdsFailure(error.message));
//   }
// };

import { fetchAdsStart, fetchAdsSuccess, fetchAdsFailure } from '../reducers/adsSlice';
import apiService from '../api-service/apiService';

export const fetchAds = (forGuest) => async (dispatch) => {
  dispatch(fetchAdsStart());

  try {
    const ads = await apiService.fetchAds(forGuest);
    dispatch(fetchAdsSuccess(ads));
  } catch (error) {
    dispatch(fetchAdsFailure(error.message));
  }
};
