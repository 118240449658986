import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  movies: [],
  loading: false,
  error: null,
};

const moviesSlice = createSlice({
  name: 'movies',
  initialState,
  reducers: {
    fetchMoviesStart(state) {
      state.loading = true;
    },
    fetchMoviesSuccess(state, action) {
      state.loading = false;
      state.movies = action.payload;
    },
    fetchMoviesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    toggleLikeSuccess(state, action) {
      const movie = state.movies.find(movie => movie.PK === action.payload);
      if (movie) {
        movie.likedByUser = !movie.likedByUser;
      }
    },
    toggleLikeFailure(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  fetchMoviesStart,
  fetchMoviesSuccess,
  fetchMoviesFailure,
  toggleLikeSuccess,
  toggleLikeFailure,
} = moviesSlice.actions;

export default moviesSlice.reducer;
