// src/redux/actions/archiveActions.js

import { fetchArchivedProgramsStart, fetchArchivedProgramsSuccess, fetchArchivedProgramsFailure } from '../reducers/archiveSlice';
import apiService from '../api-service/apiService';

export const fetchArchivedPrograms = (selectedDate) => async (dispatch, getState) => {
  dispatch(fetchArchivedProgramsStart());

  try {
    const { userData } = getState().user;
    const archivedPrograms = await apiService.fetchArchivedPrograms(userData, selectedDate);
    dispatch(fetchArchivedProgramsSuccess(archivedPrograms));
  } catch (error) {
    dispatch(fetchArchivedProgramsFailure(error.message));
  }
};
