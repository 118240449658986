import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  archivedPrograms: [],
  loading: false,
  error: null,
};

const archiveSlice = createSlice({
  name: 'archive',
  initialState,
  reducers: {
    fetchArchivedProgramsStart(state) {
      state.loading = true;
    },
    fetchArchivedProgramsSuccess(state, action) {
      state.loading = false;
      state.archivedPrograms = action.payload;
    },
    fetchArchivedProgramsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchArchivedProgramsStart,
  fetchArchivedProgramsSuccess,
  fetchArchivedProgramsFailure,
} = archiveSlice.actions;

export default archiveSlice.reducer;
