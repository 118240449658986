// import axios from 'axios';

// export const fetchEventsList = (userId, accessToken) => async (dispatch) => {
//   try {
//     dispatch({ type: 'FETCH_EVENTS_REQUEST' });
//     const response = await axios.get(`https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/${userId}/events-list`, {
//       headers: {
//         'Authorization': `Bearer ${accessToken}`
//       }
//     });
//     dispatch({ type: 'FETCH_EVENTS_SUCCESS', payload: response.data });
//   } catch (error) {
//     dispatch({ type: 'FETCH_EVENTS_FAILURE', error: error.message });
//   }
// };

import axios from "axios";

export const fetchEventsList = (userId, accessToken) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_EVENTS_REQUEST" });
    const response = await axios.get(
      `https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/${userId}/events-list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    // Dispatch both currentEvents and previousEvents
    dispatch({
      type: "FETCH_EVENTS_SUCCESS",
      payload: {
        currentEvents: response.data.currentEvents,
        previousEvents: response.data.previousEvents,
      },
    });
  } catch (error) {
    dispatch({ type: "FETCH_EVENTS_FAILURE", error: error.message });
  }
};
