// //src/redux/reducers/userSlice.js
// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   userData: null,
//   isLoading: false,
//   errorMessage: '',
// };

// const userSlice = createSlice({
//   name: 'user',
//   initialState,
//   reducers: {
//     loginStart(state) {
//       state.isLoading = true;
//     },
//     loginSuccess(state, action) {
//       state.isLoading = false;
//       state.userData = action.payload;
//       state.errorMessage = '';
//     },
//     loginFailure(state, action) {
//       state.isLoading = false;
//       state.errorMessage = action.payload;
//     },
//     logout(state) {
//       state.userData = null;
//     },
//   },
// });

// export const { loginStart, loginSuccess, loginFailure, logout } = userSlice.actions;
// export default userSlice.reducer;

// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import apiService from '../api-service/apiService';

// const initialState = {
//   userData: null,
//   isLoading: false,
//   errorMessage: '',
// };

// export const fetchUserData = createAsyncThunk('user/fetchUserData', async (_, { getState }) => {
//   const { userData } = getState().user;
//   const response = await apiService.fetchUserData(userData.id, userData.accessToken);
//   return response.data;
// });

// export const updateUserProfile = createAsyncThunk('user/updateUserProfile', async (profileData, { getState }) => {
//   const { userData } = getState().user;
//   const response = await apiService.updateUserProfile(userData.id, userData.accessToken, profileData);
//   return response.data;
// });

// const userSlice = createSlice({
//   name: 'user',
//   initialState,
//   reducers: {
//     loginStart(state) {
//       state.isLoading = true;
//     },
//     loginSuccess(state, action) {
//       state.isLoading = false;
//       state.userData = action.payload;
//       state.errorMessage = '';
//     },
//     loginFailure(state, action) {
//       state.isLoading = false;
//       state.errorMessage = action.payload;
//     },
//     logout(state) {
//       state.userData = null;
//     },
//     setGuest(state) {
//       state.userData = { isGuest: true };
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchUserData.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchUserData.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.userData = action.payload;
//         state.errorMessage = '';
//       })
//       .addCase(fetchUserData.rejected, (state, action) => {
//         state.isLoading = false;
//         state.errorMessage = action.error.message;
//       })
//       .addCase(updateUserProfile.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(updateUserProfile.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.userData = action.payload;
//         state.errorMessage = '';
//       })
//       .addCase(updateUserProfile.rejected, (state, action) => {
//         state.isLoading = false;
//         state.errorMessage = action.error.message;
//       });
//   },
// });

// export const { loginStart, loginSuccess, loginFailure, logout, setGuest } = userSlice.actions;
// export default userSlice.reducer;

// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { fetchAuthSession } from 'aws-amplify/auth';
// import apiService from '../api-service/apiService';

// const initialState = {
//   userData: null,
//   isLoading: false,
//   errorMessage: '',
// };

// export const fetchUserData = createAsyncThunk('user/fetchUserData', async (_, { getState }) => {
//   const { userData } = getState().user;
//   const response = await apiService.fetchUserData(userData.id, userData.accessToken);
//   return response.data;
// });

// const userSlice = createSlice({
//   name: 'user',
//   initialState,
//   reducers: {
//     loginStart(state) {
//       state.isLoading = true;
//     },
//     loginSuccess(state, action) {
//       state.isLoading = false;
//       state.userData = action.payload;
//       state.errorMessage = '';
//       localStorage.setItem('user', JSON.stringify(action.payload)); // Save user data to localStorage
//     },
//     loginFailure(state, action) {
//       state.isLoading = false;
//       state.errorMessage = action.payload;
//     },
//     logout(state) {
//       state.userData = null;
//       localStorage.removeItem('user'); // Remove user data from localStorage
//     },
//     setGuest(state) {
//       state.userData = { isGuest: true };
//       localStorage.setItem('user', JSON.stringify({ isGuest: true })); // Save guest state to localStorage
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchUserData.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchUserData.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.userData = action.payload;
//         state.errorMessage = '';
//         localStorage.setItem('user', JSON.stringify(action.payload)); // Save user data to localStorage
//       })
//       .addCase(fetchUserData.rejected, (state, action) => {
//         state.isLoading = false;
//         state.errorMessage = action.error.message;
//       });
//   },
// });

// export const { loginStart, loginSuccess, loginFailure, logout, setGuest } = userSlice.actions;
// export default userSlice.reducer;


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../api-service/apiService';

const initialState = {
  userData: null,
  isLoading: false,
  errorMessage: '',
};

export const fetchUserData = createAsyncThunk('user/fetchUserData', async (_, { getState }) => {
  const { userData } = getState().user;
  const response = await apiService.fetchUserData(userData.id, userData.accessToken);
  return response.data;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginStart(state) {
      state.isLoading = true;
    },
    loginSuccess(state, action) {
      state.isLoading = false;
      state.userData = action.payload;
      state.errorMessage = '';
      localStorage.setItem('user', JSON.stringify(action.payload)); // Save user data to localStorage
    },
    loginFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    logout(state) {
      state.userData = null;
      localStorage.removeItem('user'); // Remove user data from localStorage
    },
    setGuest(state) {
      state.userData = { isGuest: true };
      localStorage.setItem('user', JSON.stringify({ isGuest: true })); // Save guest state to localStorage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData = action.payload;
        state.errorMessage = '';
        localStorage.setItem('user', JSON.stringify(action.payload)); // Save user data to localStorage
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { loginStart, loginSuccess, loginFailure, logout, setGuest } = userSlice.actions;
export default userSlice.reducer;
