// src/redux/actions/sponsorsActions.js
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchSponsorsList = createAsyncThunk(
  'sponsors/fetchSponsorsList',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();
      const { userData } = user;
      const userId = userData.id;
      const accessToken = userData.identityToken;
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}${userId}/sponsor-list`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
