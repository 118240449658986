import React, { useEffect, useState } from 'react';
import apiService from '../redux/api-service/apiService';
import { FaTimes } from 'react-icons/fa';

const CategoryPopup = ({ userData, categoryId, onClose }) => {
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoryShows = async () => {
      try {
        const data = await apiService.fetchCategoryShows(userData, categoryId.split('#')[1]);
        setPrograms(data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchCategoryShows();
  }, [userData, categoryId]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-3xl h-3/4 bg-white dark:bg-gray-800 rounded-lg overflow-y-scroll">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-700 dark:text-gray-300">
          <FaTimes size={24} />
        </button>
        <div className="p-6">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">Shows in this Category</h2>
          {isLoading ? (
            <p className="text-gray-900 dark:text-gray-100">Loading shows...</p>
          ) : error ? (
            <p className="text-red-600 dark:text-red-400">Error: {error}</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {programs.map((program) => (
                <div key={program.programId} className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <img src={program.thumbnail} alt={program.title} className="w-auto h-auto object-cover rounded-lg mb-2" />
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">{program.title}</h3>
                  <p className="text-gray-600 dark:text-gray-400"> {program.days + "  "}</p>
                  <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryPopup;
